import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Init, Loading, Menu, MenuTitle, MenuTop, TemplateWindow } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faB, faCakeCandles, faCalendarCheck, faCalendarDays, faList, faListCheck, faP, faQuestion, faShoppingBag, faShoppingBasket, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';

export const Desktop = (props) => {
    const [employeeID, setEmployeeID] = useState(props.employee_id);
    const [width, setWidth] = useState(props.width);

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {

        setEmployeeID(props.employee_id)
        setWidth(props.width);

    }, [props.employee_id, props.user, props.width, props.country])

    const Press = (typ, value) => {
        func(typ, value);
    }

    return (
        <div style={{ width: props.width, height: props.height - (1 * props.offset), borderRadius: props.radius, marginTop: props.offset, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
        </div>
    );
}


