import React, { useEffect, useState } from 'react';

import './App.css';
import './globals.js';
import './themes.js';
import './items.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { AppMenu, AppStatusbar, DialogContactUs, DialogDesktopSettings, DialogGDPR, DialogHelp, DialogHelpSelect, GetDefaultDesktop, Loading, MenuInfo, MenuTitle, MenuTop, ShowError } from './items';
import { styles } from './styles';

// IMAGES
import logo from './react/app/logo.png';

import { Backdrop, CircularProgress, Fade, useMediaQuery } from '@mui/material';
import { Desktop } from './desktop';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLanguage, LoadLogin, ResetLogin } from './functions';
import { Albums } from './albums.js';
import { MyProfile } from './settings_my_profile.js';
import { LogsSystem } from './logs_system.js';
import { Stats } from './stats.js';


export const Main = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    // INTERNET CONNECTION
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // APLIKACIA
    const [appVersion, setAppVersion] = useState('');
    const [language, setLanguage] = useState(0);
    const [token, setToken] = useState('');
    const [logged, setLogged] = useState(true);
    const [menuData, setMenuData] = useState([]);
    const [menuLabel, setMenuLabel] = useState('');
    const [version, setVersion] = useState('');

    // VZHLAD APKY
    const [theme, setTheme] = useState(0);
    const [offset, setOffset] = useState(16);
    const [radius, setRadius] = useState(16);

    // MENU
    const [bodyID, setBodyID] = useState(0);
    const [menuID, setMenuID] = useState(-1);
    const [actualMenu, setActualMenu] = useState(false);
    const [label, setLabel] = useState('');

    // ORGANIZACIA - USER - NASTAVENIA
    const [organization, setOrganization] = useState(false);
    const [user, setUser] = useState(false);
    const [role, setRole] = useState(2); // Postavenie vo firme - typ účtu
    const [precision, setPrecision] = useState(3); // Precíznosť desatiných čísel
    const [settings, setSettings] = useState(false);
    const [system, setSystem] = useState(false);
    const [permissions, setPermissions] = useState(false);

    // NASTAVENIA PODLA KRAJINY
    const [locale, setLocale] = useState(false); // Aktuálna krajina

    // OKNO
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // DIALOGS
    const [showFiles, setShowFiles] = useState(false);

    // HELP
    const [showHelpSelect, setShowHelpSelect] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showContactUs, setShowContactUs] = useState(false);

    // CHYBA
    const [showError, setShowError] = useState(false);

    // BLACK FADE
    const [showBlack, setShowBlack] = useState(false);

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const window_height = height - global.topmenu_height - (2 * offset) - global.status_height;

    var lang = GetLanguage(language);

    var started = false;
    var color = global.themes[theme];

    useEffect(() => {
        Debug('------------------------', 'yellow');
        Debug('VISION CRM', 'yellow');
        Debug('------------------------', 'yellow');

        Debug('isSmall: ' + isSmall, 'gray', 'info');
        Debug('isMedium: ' + isMedium, 'gray', 'info');
        Debug('isLarge: ' + isLarge, 'gray', 'info');

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        function cleanupMenu() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }

        function handleResize() {
            setWidth(window.innerWidth);
            //setHeight(window.innerHeight);
        }

        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        if (started == false) {
            started = true;

            if (window.innerWidth <= global.device_small) {
                // MOBILE
                setRadius(0);
                setOffset(0);
            } else {
                // DESKTOP
                setRadius(global.radius);
                setOffset(global.offset);

                // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
                window.addEventListener('resize', handleResize);
            }

            setWidth(window.innerWidth);
            setHeight(window.innerHeight);

            //lang = LoadLanguage();
            //setMenuData(lang.menu);

            var login = LoadLogin();
            db_token_check(login.token, true);

        }

        // Pravidelná kontrola aplikácie
        // ! SPOSOBUJE PROBLEMY - PREKRESLENIE A VYMAZANIE Rozpísaného textového pola
        /*
        let intervalID = setInterval(() => {
            Debug('##### TIMER -> check data #####');

            var login = LoadLogin();
            db_token(login.token, true);
        }, global.check_period);
        */

        // REMOVE AFTER APPLICATION ENDS
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
            window.removeEventListener('resize', handleResize);
            cleanupMenu();
            //clearInterval(intervalID);
        };

    }, [window.innerWidth, isOnline])

    const logout = () => {
        ResetLogin();
        navigate('/filatelia/logout');
    }

    const db_token_check = async (token, home) => {
        // získanie údajov USER -> z tokenu + aktualizácia informácii
        if (isBusy == false) {

            if (isBusy == true) {
                setBusy(true);
            }
            try {
                const response = await fetch(
                    global.db_url + 'token_check', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token
                    })
                })

                const json = await response.json();

                Debug('Token checking...', 'red');
                Debug(json);

                setBusy(false);
                if (json.ok > 0) {
                    if (json.authorized == true) {
                        var user_ = json.user;

                        if (user_ != false && json.organization != false) {
                            //user_.super_admin = false;

                            setMenuData(GetLanguage(user_.language_id).menu);
                            setUser(user_);
                            setToken(token);
                            setLanguage(user_.language_id);
                            setTheme(user_.theme_id);
                            setOrganization(json.organization);

                            // SYSTEMOVE NASTAVENIE APLIKACIE
                            if (json.settings_system == false) {
                                setSystem(global.settings_default);
                            } else {
                                var system = json.settings_system;
                                setSystem(system);
                            }

                            // UZIVATELSKE NASTAVENIE APLIKACIE
                            if (json.settings == false) {
                                setSettings(global.settings_user_default);
                            } else {
                                setSettings(json.settings);
                            }

                            if (home == true) {
                                setMenuID(0);
                            }

                        } else {
                            logout();
                        }
                    } else {
                        logout();
                    }
                } else {
                    setShowError(true);
                }

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const MenuTopPress = (typ, item) => {
        if (typ == 2) {
            // ZMENA KRAJINY
            setLocale(item);
        }
    }

    const MenuPress = (typ, item) => {

        if (typ == 0) {
            Home();
        }
        if (typ == 1) {
            if (item.id == 0) {
                // SPAT NA HLAVNE MENU
                Home();
            } else {
                if (item.children != undefined) {
                    // zobrazenie children menu
                    setMenuData(item.children);
                    setMenuLabel(item.name);
                } else {
                    // vykonanie príkazu - Kontrola povolenia na čítanie                    
                    if (actualMenu != false) {
                        if (actualMenu.reload == true) {
                            // RELOAD TOKEN
                            var login = LoadLogin();
                            db_token_check(login.token, false);
                        }
                    }
                    setActualMenu(item);
                    setLabel(item.label);
                    setBodyID(item.id);
                }
            }
        }
        if (typ == 999) {
            // ID MENU OPENED            
            setMenuID(item.id);
        }
    }

    const Home = () => {
        // MENU -> HOME
        //setMenuData(lang.menu);
        //setMenuLabel('');
        //setActualMenu(false);
        setBodyID(0);

        var login = LoadLogin();
        db_token_check(login.token, true);
    }

    const StatusbarPress = (id) => {
        if (id == 1) {
            // EXPORTOVANE SUBORY
            setShowFiles(true);
        }
    }

    const DesktopPress = (typ, value) => {

    }

    const closeWindow = (redraw) => {
        setBodyID(0);

        if (redraw == true) {
            var login = LoadLogin();
            db_token_check(login.token, false);
        }
    }

    const HelpSelectResult = (typ) => {
        setShowHelpSelect(false);

        if (typ == 1) {
            // POMOCNIK
            setShowHelp(true);
        }
        if (typ == 2) {
            // KONTAKTUJTE NAS
            setShowContactUs(true);

        }
    }


    return (
        <div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundColor: color.desktop_background, backgroundSize: 'cover' }} className='container'>
            <div style={{ ...styles.BlockLeft, height: height }}>

                {/* MENU - VRCHNA LISTA */}
                <MenuTop theme={theme} user={user} organization={organization} permissions={permissions} menuID={menuID} version={version} label={label} offset={offset} radius={radius} isSmall={isSmall} token={token} bodyID={bodyID} lang={lang} screenWidth={width} width={width} height={height} logged={logged} locale={locale} func={MenuTopPress.bind(this)} />

                <div style={{ ...styles.BlockRow, height: height - global.topmenu_height }}>
                    {(bodyID == 0) || (bodyID > 0 && width > global.max_screen) ?
                        <div style={{ ...styles.BlockCenter, alignItems: 'center', width: global.menu_width, height: height - global.topmenu_height }}>

                            {/* INFO MENU */}
                            <MenuInfo organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} menuID={menuID} bodyID={bodyID} token={token} func={MenuPress.bind(this)} />

                            {/* MAIN MENU */}
                            <AppMenu theme={theme} menuID={menuID} version={version} user={user} system={system} settings={settings} permissions={permissions} label={menuLabel} offset={offset} radius={radius} isSmall={isSmall} bodyID={bodyID} token={token} lang={lang} language={language} width={width} menuData={menuData} height={height - global.topmenu_height - global.menu_info_height} func={MenuPress.bind(this)} />

                        </div>
                        : null
                    }

                    {/* BODY */}
                    <div style={{ ...styles.Block, width: (bodyID == 0) || (bodyID > 0 && width > global.max_screen) ? width - global.menu_width : width, height: height - global.topmenu_height }}>

                        <div style={{ ...styles.Block, height: height - global.topmenu_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {logged == true ?
                                bodyID == 0 ?
                                    <div style={{ ...styles.Block, alignItems: width > global.max_screen ? 'flex-start' : 'flex-start' /*, height: height - global.statusbar_height - 0*/, paddingTop: isSmall ? global.smallOffset : offset }}>
                                        {/* DESKTOP */}
                                        <Desktop theme={theme} system={system} settings={settings} user={user} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} isMedium={isMedium} title={''} sub_title={''} screenWidth={width} width={isSmall ? width : width - global.menu_width - (3 * offset)} height={height - global.topmenu_height - global.menutitle_height - offset - global.status_height} token={token} func={DesktopPress.bind(this)} />
                                    </div>
                                    :
                                    <div style={{ ...styles.Block }}>

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * FILATELIA
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* ZOZNAM ROCNIKOV */}
                                        {bodyID == 300 ?
                                            <Albums organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.albums} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                            : null}

                                        {/* Štatistika */}
                                        {bodyID == 301 ?
                                            <Stats organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.stat} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * NASTAVENIA
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* ZOZNAM ZAKAZNIKOV */}
                                        {bodyID == 9005 ?
                                            <MyProfile organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.user_profile} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                            : null}
                                        {/* SYSTEMOVE LOGY */}
                                        {bodyID == 9004 ?
                                            <LogsSystem organization={organization} user={user} system={system} settings={settings} permissions={permissions} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.system_logs} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (2 * offset) : width - (2 * offset)} height={window_height} token={token} func={() => closeWindow(true)} />
                                            : null}

                                    </div>
                                :
                                null
                            }
                        </div>

                        {/*
                        <AppStatusbar theme={theme} menuID={menuID} user={user} system={system} settings={settings} permissions={permissions} offset={offset} radius={radius} isSmall={isSmall} bodyID={bodyID} token={token} lang={lang} language={language} width={width > global.max_screen ? width - global.menu_width : width} func={StatusbarPress.bind(this)} />
                        */}

                    </div>

                </div>

            </div>

            {/* HELP -> VYBER POMOCNIK / KONTAKTUJTE NAS */}
            {showHelpSelect == true ?
                <DialogHelpSelect isSmall={isSmall} theme={theme} user={user} radius={radius} language={language} lang={lang} title={lang.help} sub_title={lang.vision_system} background={color.dark_gray} offset={offset} func={HelpSelectResult.bind(this)} />
                : null}

            {/* HELP -> KONTAKTUJTE NAS */}
            {showContactUs == true ?
                <DialogContactUs isSmall={isSmall} theme={theme} user={user} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={color.dark_gray} offset={offset} func={() => setShowContactUs(false)} />
                : null}

            {/* HELP -> POMOCNÍK */}
            {showHelp == true ?
                <DialogHelp isSmall={isSmall} theme={theme} user={user} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={color.dark_gray} offset={offset} func={() => setShowHelp(false)} />
                : null}

            {showError == true ?
                <ShowError theme={theme} text={lang.app_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading theme={theme} side={0} func={() => setBusy(false)} />
                : null}

            {/* VYPADOK INTERNETOVEHO PRIPOJENIA */}
            {isOnline == false ?
                <ShowError theme={theme} text={lang.internet_error} sub_text={lang.internet_error_text} icon={faWifi} func={() => setShowError(false)} />
                : null}

            {/* FADE BLACK -> ZMENA TEMY */}
            <Backdrop open={showBlack} transitionDuration={500} style={{ zIndex: 20000, width: width, height: height, backgroundColor: '#000000' }}>
                <div style={{ ...styles.Block, width: width, height: height }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 300, maxHeight: 120, objectFit: 'contain' }}></img>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <CircularProgress style={{ color: '#FFFFFF' }} thickness={2} size={20} />
                    </div>
                </div>
            </Backdrop>

        </div >
    );
}


