/*
*
* =================================================================================================================

    UDAJE O ZNAMKE

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <Dialog organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';
import { Avatar, ButtonNew, DialogTabs, EmptyList, FormChips, FormError, FormLabel, FormNote, FormSelect, FormSpace, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, FormControlLabel, IconButton, MenuItem, Pagination, Paper, Radio, RadioGroup, Select, Skeleton, Stack, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faInfoCircle, faLayerGroup, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Debug, GetLeftPosition, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { GetTodayDatum, GetTodayYear, Today } from './functions_date';

export const Stamp = (props) => {

    var lang = props.lang;
    let color = global.themes[props.theme];
    let albumID = props.album_id;
    let stateID = props.state_id;
    let moneys = global.states[stateID].money;

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [loaded, setLoaded] = useState(props.item == false ? true : false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [index, setIndex] = useState(props.item == false ? 1 : 0);

    // DATA
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [avatar, setAvatar] = useState('');
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [note, setNote] = useState('');
    const [code, setCode] = useState('');
    const [page, setPage] = useState(props.item == false ? props.page_uid : '');
    const [cost, setCost] = useState('');
    const [price, setPrice] = useState('');
    const [money, setMoney] = useState(moneys[0].id);
    const [pricesMoney, setPricesMoney] = useState(props.state_id == 0 ? global.money[2].name : global.money[1].name);
    const [priceCatalogue, setPriceCatalogue] = useState('');
    const [dateCatalogue, setDateCatalogue] = useState(props.state_id == 0 ? '2017-01-01' : props.state_id == 1 ? '2019-01-01' : '2015-01-01');
    const [priceMarket, setPriceMarket] = useState('');
    const [currency, setCurrency] = useState(moneys[0].name);


    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);


    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 800 ? 800 : height;

    const column1 = 200;
    const column2 = 600 - column1 - 30;

    const buttonHeight = 70;
    const errorHeight = 40;
    const titleHeight = 60;

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {

            if (props.item != false) {
                // UDAJE Z DB
                db_get();
            } else {
                if (props.state_id == 0) {
                    if (parseInt(props.year) < 2009) {
                        setMoney(0);
                        setCurrency(global.money[0].name);
                    } else {
                        setMoney(2);
                        setCurrency(global.money[2].name);
                    }
                }
                setTimeout(() => {
                    inputRef.current.focus();
                }, 300);
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stamp', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    assignData(item);
                    setLoaded(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stamp_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                func(true);
                setRedrawRequired(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const assignData = (item) => {
        setEnabled(item.enabled == false ? false : true);
        setAvatar(item.image);
        setName(item.name);
        setCode(item.code);
        setPage(item.page);
        setCost(item.cost);
        setMoney(item.money);
        setPrice(item.price);
        setPriceCatalogue(item.price_catalogue);
        setDateCatalogue(item.date_catalogue);
        setPriceMarket(item.price_market);
        setNote(item.note);
    }

    const Save = () => {
        setError('');
        var error = 0;

        var error = 0;
        error += IsEmpty(price) == true ? 1 : 0;
        error += IsEmpty(page) == true ? 1 : 0;

        if (error == 0) {
            let data = {
                id: itemID,
                user_id: props.user.id,
                album_id: albumID,
                enabled: true,
                image: avatar,
                name: name.trim(),
                code: code,
                page: page.replaceAll(' ', ''),
                cost: cost,
                price: price,
                money: money,
                price_catalogue: priceCatalogue,
                date_catalogue: dateCatalogue,
                price_market: parseFloat(priceMarket) == 0 || priceMarket == '' ? priceCatalogue : priceMarket,
                note: note.trim()
            };

            Debug(data);
            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const ChangeMoney = (id) => {
        setMoney(id);

        let tmp = moneys.find(x => x.id == id);
        if (tmp != undefined) {
            setCurrency(tmp.name);
        }

    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faFile} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.stamp_new_ : lang.stamp}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {loaded != false ?
                            <div style={{ paddingBottom: 100 }}>
                                <FormSpace />
                                <div style={{ ...styles.Block }}>
                                    <Avatar editable={true} picture image={avatar} size={100} theme={props.theme} func={AvatarPress.bind()} />
                                    <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.stamp_image}</p>
                                </div>
                                <FormSpace />
                                <Line theme={props.theme} />
                                <FormSpace />
                                <FormLabel editing={true} editButton={false} icon={faInfoCircle} title={lang.stamp_info} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormText inputRef={inputRef} require redraw value={code} editing={true} title={lang.stamp_code} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCode(txt)} />
                                <FormText require redraw value={page} editing={true} title={lang.stamp_page} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPage(txt)} />
                                <FormSpace />
                                <Line theme={props.theme} />
                                <FormSpace />
                                <FormLabel editing={true} editButton={false} title={lang.stamp_prices} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormSelect value={money} items={moneys} field={'name'} editing={true} title={lang.stamp_money} width={100} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => ChangeMoney(id)} />
                                <FormText require redraw numeric calc money currency={currency} value={price} editing={true} title={lang.stamp_price} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPrice(txt)} />
                                <FormSpace />
                                <Line theme={props.theme} />
                                <FormSpace />
                                <FormLabel editing={true} editButton={false} title={lang.stamp_prices_} column1={column1} column2={column2} marginTop={0} theme={props.theme} lang={lang} radius={props.radius} func={null} />
                                <FormText redraw calc money currency={pricesMoney} value={cost} editing={true} title={lang.stamp_cost} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setCost(txt)} />
                                <FormNote title={lang.stamp_cost_note} column1={column1} column2={column2} marginTop={2} marginBottom={0} theme={props.theme} lang={lang} radius={props.radius} />
                                <FormText redraw calc money currency={pricesMoney} value={priceCatalogue} editing={true} title={lang.stamp_price_catalogue} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPriceCatalogue(txt)} />
                                <FormText redraw date value={dateCatalogue} editing={true} title={lang.stamp_date_catalogue} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setDateCatalogue(txt)} />
                                <FormSpace />
                                <FormText redraw calc money currency={pricesMoney} value={priceMarket} editing={true} title={lang.stamp_price_market} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setPriceMarket(txt)} />
                                <FormSpace />

                            </div>
                            :
                            <Stack spacing={1}>
                                <FormSpace />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                            </Stack>}
                    </div>

                    <div style={{ ...styles.Block, height: errorHeight }}>
                        <FormError small margin={0} error={error} theme={props.theme} />
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 10 }}>{lang.save}</Button>
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {selectPhoto == true ?
                    <Photos autoSelect full typ={global.image.others} max_size={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}