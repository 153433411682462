/*
*
* =================================================================================================================
* ALBUMY
* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Avatar, ButtonNew, DialogTabs, DialogYesNo, EmptyList, FormChips, FormError, FormNote, FormSelect, FormSpace, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Chip, FormLabel, IconButton, Pagination, Paper, Skeleton, Stack, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faLayerGroup, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { Stamp } from './stamp';


export const Stats = (props) => {

    var lang = props.lang;
    let color = global.themes[props.theme];


    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [albumInfo, setAlbumInfo] = useState(false);
    const [money, setMoney] = useState(global.money[0].name);

    // VYBER STATU
    const [stateID, setStateID] = useState(-1);
    const [states, setStates] = useState(false);

    // VALUES
    const [value, setValue] = useState(0);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 70;
    const menuWidth = global.sub_menu_width;

    const tabs = [
        { id: 0, enabled: true, label: lang.users_list },
        { id: 1, enabled: true, label: lang.users_groups },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            var states = global.states;
            var tmp = [];
            states.forEach(state => {
                tmp.push(state);
            });
            tmp.push({ id: -1, name: lang.stat_choose });

            setStates(tmp);

            // UDAJE Z DB

            running = true;
        }

    }, []);

    const db_get = async (filter) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'stats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setAlbumInfo(json.item);
                setMoney(global.money[json.item.money].name);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeState = (id) => {
        setStateID(id);

        let filter = { state_id: id };
        db_get(filter);

    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight, marginLeft: 10 }}>
                                    <SelectInput redraw default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={stateID} items={global.states} field={'name'} label={lang.album_country} radius={global.radius} func={(txt) => ChangeState(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: '67%', height: menuHeight }}>
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                {albumInfo != false ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextXXLarge }}>{global.states[albumInfo.state_id].name} <b>{albumInfo.years}</b></p>
                                        <FormSpace></FormSpace>

                                        <p style={{ ...styles.TextXSmall }}>{lang.album_pages_count}: {albumInfo.page_count}</p>
                                        <p style={{ ...styles.TextXSmall }}>{lang.album_stamps_count}: {albumInfo.stamps_count}</p>

                                        <div style={{ ...styles.BlockRowRaw, paddingBottom: 2, marginTop: 20 }}>
                                            <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                <p style={{ ...styles.TextXSmall }}>{lang.stamp_price}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.price_sum, 2)} {global.money[albumInfo.money].name}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                            <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                <p style={{ ...styles.TextXSmall }}>{lang.stamp_cost}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.cost_sum, 2)} {money}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                            <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                <p style={{ ...styles.TextXSmall }}>{lang.stamp_price_catalogue}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.price_catalogue_sum, 2)} {money}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                            <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                <p style={{ ...styles.TextXSmall }}>{lang.stamp_price_market}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.price_market_sum, 2)} {money}</p>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    : null}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}
