/*
*
* =================================================================================================================
* ALBUMY
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './styles.css';

import { Avatar, ButtonNew, DialogTabs, DialogYesNo, EmptyList, FormChips, FormError, FormNote, FormSelect, FormSpace, FormText, FormYesNo, Icon, Line, Loading, Menu, MenuTitle, MenuTop, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Chip, FormLabel, IconButton, Pagination, Paper, Skeleton, Stack, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faLayerGroup, faPlusCircle, faPrint, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CheckPermission, Debug, FormatMoney, GetPages, IsEmpty } from './functions';
import { Photos } from './photos';
import { Stamp } from './stamp';


export const Albums = (props) => {

    var lang = props.lang;
    let color = global.themes[props.theme];


    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VYBER STATU
    const [stateID, setStateID] = useState(-1);
    const [states, setStates] = useState(false);

    // VALUES
    const [value, setValue] = useState(0);

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 70;
    const menuWidth = global.sub_menu_width;

    const tabs = [
        { id: 0, enabled: true, label: lang.users_list },
        { id: 1, enabled: true, label: lang.users_groups },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            var states = global.states;
            var tmp = [];
            states.forEach(state => {
                tmp.push(state);
            });
            tmp.push({ id: -1, name: lang.album_all_countries });

            Debug(tmp);
            setStates(tmp);

            // UDAJE Z DB
            db_get(0, false);

            running = true;
        }

    }, []);

    const db_get = async (page, filter) => {
        setBusy(true);
        Debug(filter);
        try {
            const response = await fetch(
                global.db_url + 'albums', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetFilter = () => {
        let filter = {};

        if (search != '') {
            filter.year = search;
        }
        if (stateID != -1) {
            filter.state_id = stateID;
        }

        return filter;
    }

    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 3) {
            var filter = GetFilter();
            filter.year = txt;
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, false);
        }
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setItemSelected(false);
        setShowDialog(true);
    }

    const SelectItem = (item) => {
        if (CheckPermission(props.user.super_admin, props.permissions['products_manufactures']).edit == true) {
            setItemSelected(item);
            setShowDialog(true);
        }
    }

    const DialogResult = (redraw) => {
        setShowDialog(false);

        if (redraw == true) {
            db_get(page, filter);
        }
    }

    const ChangeState = (id) => {
        setStateID(id);

        var filter = GetFilter();
        if (id > -1) {
            filter.state_id = id;
        } else {
            delete (filter.state_id);
        }

        db_get(0, filter);

    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                <div style={{ ...styles.BlockLeft, width: '33%', height: menuHeight }}>
                                    <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.album_search} func={(txt) => Search(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: menuHeight, marginLeft: 10 }}>
                                    <SelectInput redraw default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={stateID} items={states} field={'name'} label={lang.album_country} radius={global.radius} func={(txt) => ChangeState(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: menuHeight }}>
                                    {CheckPermission(props.user.super_admin, props.permissions['products_manufactures']).editing == true ?
                                        <Button onClick={() => AddNew()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_add, width: 180 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 6 }} icon={faPlusCircle} />
                                            {lang.album_new}
                                        </Button>
                                        : null}
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => SelectItem(item)}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '40%', height: rowHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{global.states[item.state_id].name}</p>
                                                {item.note != '' ?
                                                    <p style={{ ...styles.TextXSmall, color: color.dark_red }}>{item.note}</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '50%', height: rowHeight, justifyContent: 'center' }}>
                                                <Chip label={<p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.years}</p>} style={{ margin: 2 }} variant="outlined" />
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                    <EmptyList row small lang={lang} />
                                }

                                {/* PAGINATION */}
                                {pages > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                    </div>
                                    : null}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showDialog == true ?
                <Album item={itemSelected} state_id={stateID} organization={props.organization} user={props.user} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={DialogResult.bind()} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


/*
*
* =================================================================================================================
* UPRAVA / NOVY ZÁZNAM
* =================================================================================================================
*
*/
export const Album = (props) => {

    var lang = props.lang;
    let color = global.themes[props.theme];

    let tabs = [
        { id: 0, enabled: true, label: lang.album_stamps },
        { id: 3, enabled: true, label: lang.album_pages },
        { id: 2, enabled: true, label: lang.album_info },
        { id: 1, enabled: true, label: lang.album_settings },
    ];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [loaded, setLoaded] = useState(props.item == false ? true : false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [index, setIndex] = useState(props.item == false ? 1 : 0);
    const [overID, setOverID] = useState(-1);

    // DATA
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [avatar, setAvatar] = useState('');
    const [stateID, setStateID] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [name, setName] = useState('');
    const [note, setNote] = useState('');
    const [page, setPage] = useState('');
    const [years, setYears] = useState('');
    const [albumInfo, setAlbumInfo] = useState(false);
    const [states, setStates] = useState(false);
    const [money, setMoney] = useState(global.money[0].name);


    // DIALOGS
    const [selectPhoto, setSelectPhoto] = useState(false);
    const [showAskExport, setShowAskExport] = useState(false);


    // ZNAMKY
    const [stamps, setStamps] = useState(false);
    const [stampSearch, setStampSearch] = useState('');
    const [stampsFiltered, setStampsFiltered] = useState(false);
    const [stampSelected, setStampSelected] = useState(false);
    const [showStamp, setShowStamp] = useState(false);

    // PDF
    const [pdfLink, setPdfLink] = useState(false);

    // LISTY
    const [pageUID, setPageUID] = useState('');
    const [pages, setPages] = useState(false);
    const [pageSearch, setPageSearch] = useState('');
    const [pagesFiltered, setPagesFiltered] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 900 ? 900 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const column1 = 180;
    const column2 = 600 - column1 - 30;

    const buttonHeight = 70;
    const errorHeight = 0;
    const titleHeight = 60;
    const rowHeight = 100;
    const rowListHeight = 180;
    const rowStampHeight = 50;
    const tableHeight = 30;

    let { func } = props;
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            let tmp = global.states.filter(x => x.id > -1);
            setStates(tmp);

            if (props.item != false) {
                // UDAJE Z DB
                db_stamps();
                db_get();
            } else {
                if (props.state_id > -1) {
                    setStateID(props.state_id);
                }
            }

            running = true;
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'album', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    let item = json.item;
                    setEnabled(item.enabled == false ? false : true);
                    setAvatar(item.image);
                    setStateID(item.state_id);
                    setYears(item.years);
                    setName(item.name);
                    setPage(item.pages);
                    setNote(item.note);

                    if (item.state_id == 0) {
                        setMoney(global.money[2].name);
                    } else {
                        setMoney(global.money[1].name);
                    }

                    setLoaded(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'album_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (data.id == 0) {
                    // NOVY ZAZNAM
                    setItemID(json.item_id);
                    setIndex(0);
                    db_stamps();
                } else {
                    // UPDATE ZAZNAMU
                    setShowOK(true);
                }
                setRedrawRequired(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_stamps = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stamps', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    album_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStamps(json.items);
                setStampsFiltered(json.items);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_pages = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'pages', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    album_id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                let tmp = json.items;
                setPages(tmp);
                setPagesFiltered(tmp);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_album = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'album', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    id: itemID
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setAlbumInfo(json.item);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stat_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------
                    state_id: stateID,
                    album_id: itemID,
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setPdfLink('demo.pdf');
                setTimeout(() => {
                    inputRef.current.click();
                }, 400);

            } else {
                setShowError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(redrawRequired);
    }

    const Save = () => {
        setError('');
        var error = 0;
        if (years == '') {
            error++;
        }

        let year = parseInt(years);
        if (year < 1900 || year > 2100) {
            error++;
        }

        if (error == 0) {
            let data = {
                id: itemID,
                user_id: props.user.id,
                state_id: stateID,
                enabled: true,
                image: avatar,
                name: name.trim(),
                years: year,
                pages: page == '' ? 0 : page,
                note: note.trim()
            };

            db_update(data);

        } else {
            setError(lang.required_red);
        }

    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const ChangeTab = (id) => {
        setIndex(id);
        if (id == 0) {
            db_stamps();
        }
        if (id == 3) {
            db_pages();
        }
        if (id == 2) {
            db_album();
        }
    }

    const StampAdd = (uid) => {
        setStampSelected(false);
        setPageUID(uid);
        setShowStamp(true);
    }

    const StampResult = (redraw) => {
        setShowStamp(false);

        if (redraw) {
            if (index == 0) {
                db_stamps();
            }
            if (index == 3) {
                db_pages();
            }
        }
    }

    const SelectItem = (item) => {
        setPageUID('');
        setStampSelected(item);
        setShowStamp(true);
    }

    const FilterStamps = (txt) => {
        var tmp = stamps;
        if (txt.length > 0) {
            tmp = stamps.filter(x => x.code.toLowerCase().startsWith(txt.toLowerCase()));
        }
        setStampsFiltered(tmp);
        setStampSearch(txt);
    }

    const FilterPages = (txt) => {
        var tmp = pages;
        if (txt.length > 0) {
            tmp = pages.filter(x => x.page.toLowerCase().startsWith(txt.toLowerCase(), 0));
        }
        setPagesFiltered(tmp);
        setStampSearch(txt);
    }

    const Export = (value) => {
        setShowAskExport(false);

        if (value == true) {
            db_export();
        }
    }

    const Print = () => {

    }

    return (
        <Backdrop open={true} style={{ paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <Icon theme={props.theme} icon={faBookOpen} />
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{itemID == 0 ? lang.album_new : lang.album + ' - ' + global.states[stateID].name + ' ' + years}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {itemID > 0 ?
                        <DialogTabs center value={index} items={tabs} width={dialogWidth / 4} theme={props.theme} func={(id) => ChangeTab(id)} />
                        : null}

                    {index == 0 ?
                        /*
                        *
                        * =================================================================================================================
                        * ZNAMKY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                <div style={{ ...styles.BlockLeft, width: '33%', height: titleHeight }}>
                                    <p style={{ ...styles.TextXSmall }}>{lang.stamp_album_list}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: titleHeight }}>
                                    <TextInput search theme={props.theme} enabled={true} lang={lang} value={stampSearch} label={''} placeholder={lang.stamp_search} func={(txt) => FilterStamps(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: titleHeight }}>
                                    <ButtonNew icon={faPlusCircle} label={lang.stamp_new} theme={props.theme} func={() => StampAdd('')} />
                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, width: '96%', marginBottom: global.list_padding }}>
                                {stampsFiltered != false ? stampsFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        onClick={() => SelectItem(item)}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            cursor: 'pointer',
                                            marginTop: 5, marginBottom: 5,
                                            paddingTop: 2, paddingBottom: 2,
                                            borderLeft: item.enabled == false ? color.disabled_border : '',
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: rowHeight, justifyContent: 'center' }}>
                                                <Avatar picture image={item.image} size={rowHeight - 8} theme={props.theme} />
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '60%', height: rowHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.number}: {item.code}</p>
                                                {item.name != '' ?
                                                    <p style={{ ...styles.TextXSmall }}>{lang.label}: {item.name}</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 120, height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.stamp_page}</p>
                                                <Chip label={item.page} style={{ marginTop: 5 }} variant="outlined" />
                                            </div>
                                            <div style={{ ...styles.Block, width: 120, height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{lang.stamp_price}</p>
                                                <p style={{ ...styles.TextXSmall, marginTop: 5, fontWeight: '600' }}>{FormatMoney(item.price, 2)} {global.money[item.money].name}</p>
                                            </div>
                                        </div>
                                    </Paper>
                                )) : null}
                            </div>
                        </div>
                        : null}

                    {index == 3 ?
                        /*
                        *
                        * =================================================================================================================
                        * LISTY
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.BlockRow, width: '96%' }}>
                                <div style={{ ...styles.BlockLeft, width: '33%', height: titleHeight }}>
                                    <p style={{ ...styles.TextXSmall }}>{lang.album_pages_list}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: titleHeight }}>
                                    <TextInput search theme={props.theme} enabled={true} lang={lang} value={pageSearch} label={''} placeholder={lang.album_page_search} func={(txt) => FilterPages(txt)} />
                                </div>
                                <div style={{ ...styles.BlockRight, width: '33%', height: titleHeight }}>

                                </div>
                            </div>

                            <div style={{ ...styles.BlockCenter, width: '96%', marginBottom: global.list_padding }}>
                                {pagesFiltered != false ? pagesFiltered.map((item, index) => (
                                    <Paper
                                        key={item.id}
                                        elevation={item.id == overID ? 4 : 1}
                                        style={{
                                            ...styles.Block,
                                            backgroundColor: item.enabled == false ? color.disabled : color.white,
                                            borderRadius: global.items_radius,
                                            minHeight: rowHeight,
                                            marginTop: 10, marginBottom: 10,
                                            paddingTop: 2, paddingBottom: 2,
                                        }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: '60%', height: rowListHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                <Chip label={<p style={{ ...styles.TextSmall, fontWeight: '600' }}><span style={{ ...styles.TextTiny }}>{lang.stamp_page}:</span> {item.page}</p>} style={{ margin: 2, backgroundColor: color.lighteen_red }} variant="outlined" />
                                                <p style={{ ...styles.TextXSmall, marginTop: 5, marginLeft: 0 }}>{lang.album_list_stamp_count}: {item.stamp_count}</p>

                                                <div style={{ ...styles.BlockRowRaw, paddingBottom: 2, marginTop: 20 }}>
                                                    <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{lang.stamp_price}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 100 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(item.price_sum, 2)} {global.money[item.money].name}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                                    <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{lang.stamp_cost}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 100 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(item.cost_sum, 2)} {money}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                                    <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{lang.stamp_price_catalogue}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 100 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(item.price_catalogue_sum, 2)} {money}</p>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                                    <div style={{ ...styles.BlockLeft, width: 180 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{lang.stamp_price_market}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: 100 }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(item.price_market_sum, 2)} {money}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div style={{ ...styles.BlockRight, width: '40%', height: 60, justifyContent: 'center' }}>
                                                <Button onClick={() => StampAdd(item.page)} style={{ ...styles.ButtonThemed, backgroundColor: color.light_gray, width: 180, color: color.black }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: color.white, marginRight: 10, color: color.black }} icon={faPlusCircle} />
                                                    {lang.stamp_new}
                                                </Button>
                                            </div>
                                        </div>

                                        <div style={{ ...styles.Block, width: '98%', marginTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{lang.album_page_stamps}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRow, border: color.border, marginTop: 5 }}>
                                                <div style={{ ...styles.BlockLeft, width: rowStampHeight, height: tableHeight, justifyContent: 'center', marginLeft: 5 }}>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, height: tableHeight, width: '30%', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginLeft: 10 }}>{lang.stamp_code}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, height: tableHeight, width: '20%', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginRight: 10 }}>{lang.stamp_price}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, height: tableHeight, width: '20%', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginRight: 10 }}>{lang.stamp_price_catalogue}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, height: tableHeight, width: '20%', borderLeft: color.border }}>
                                                    <p style={{ ...styles.TextTiny, color: color.dark_gray, marginRight: 10 }}>{lang.stamp_price_market}</p>
                                                </div>
                                            </div>

                                            {item.stamps != false ? item.stamps.map((stamp, i) => (
                                                <Paper
                                                    key={stamp.id}
                                                    onClick={() => SelectItem(stamp)}
                                                    style={{
                                                        ...styles.BlockRow,
                                                        height: rowStampHeight,
                                                        backgroundColor: color.lighteen_blue,
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        cursor: 'pointer'
                                                    }}>
                                                    <div style={{ ...styles.BlockLeft, width: rowStampHeight, height: rowStampHeight, justifyContent: 'center', marginLeft: 5 }}>
                                                        <Avatar picture image={stamp.image} size={rowStampHeight - 4} theme={props.theme} />
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, height: rowStampHeight, width: '30%' }}>
                                                        <p style={{ ...styles.TextXSmall, marginLeft: 10 }}>{stamp.code}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, height: rowStampHeight, width: '20%' }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(stamp.price, 2)} {global.money[stamp.money].name}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, height: rowStampHeight, width: '20%' }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(stamp.price_catalogue, 2)} {money}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, height: rowStampHeight, width: '20%' }}>
                                                        <p style={{ ...styles.TextXSmall }}>{FormatMoney(stamp.price_market, 2)} {money}</p>
                                                    </div>

                                                </Paper>
                                            )) : null}
                                        </div>
                                    </Paper>
                                )) : null}
                            </div>
                        </div>
                        : null}

                    {index == 2 ?
                        /*
                        *
                        * =================================================================================================================
                        * INFO O ALBUME
                        * =================================================================================================================
                        *
                        */
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight }}>
                            {albumInfo != false ?
                                <div style={{ ...styles.Block, paddingTop: 20 }}>

                                    <p style={{ ...styles.TextXXLarge }}>{global.states[albumInfo.state_id].name} <b>{albumInfo.years}</b></p>
                                    <FormSpace></FormSpace>

                                    <p style={{ ...styles.TextXSmall }}>{lang.album_pages_count}: {albumInfo.pages_count}</p>
                                    <p style={{ ...styles.TextXSmall }}>{lang.album_stamps_count}: {albumInfo.stamps_count}</p>

                                    <div style={{ ...styles.BlockRowRaw, paddingBottom: 2, marginTop: 20 }}>
                                        <div style={{ ...styles.BlockLeft, width: 180 }}>
                                            <p style={{ ...styles.TextXSmall }}>{lang.stamp_price}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 100 }}>
                                            <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.price_sum, 2)} {global.money[albumInfo.money].name}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                        <div style={{ ...styles.BlockLeft, width: 180 }}>
                                            <p style={{ ...styles.TextXSmall }}>{lang.stamp_cost}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 100 }}>
                                            <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.cost_sum, 2)} {money}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                        <div style={{ ...styles.BlockLeft, width: 180 }}>
                                            <p style={{ ...styles.TextXSmall }}>{lang.stamp_price_catalogue}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 100 }}>
                                            <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.price_catalogue_sum, 2)} {money}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockRowRaw, paddingBottom: 2 }}>
                                        <div style={{ ...styles.BlockLeft, width: 180 }}>
                                            <p style={{ ...styles.TextXSmall }}>{lang.stamp_price_market}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 100 }}>
                                            <p style={{ ...styles.TextXSmall }}>{FormatMoney(albumInfo.price_market_sum, 2)} {money}</p>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.Block, marginTop: 80 }}>
                                        <p style={{ ...styles.TextXSmall, marginBottom: 8 }}>{lang.stat_export_note}</p>
                                        <Button onClick={() => setShowAskExport(true)} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.stat_export}</Button>
                                    </div>

                                    {pdfLink != false ?
                                        <div style={{ ...styles.Block, marginTop: 20 }}>
                                            <a ref={inputRef} href={global.pdf_exported + pdfLink} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, textDecoration: 'none' }} target='_blank'>
                                                <div style={{ ...styles.Block, height: 36 }}>
                                                    <p style={{ ...styles.TextXSmall, color: color.white }}>{lang.pdf_show}</p>
                                                </div>
                                            </a>
                                        </div>
                                        : null}

                                </div>
                                : null}

                        </div>
                        : null}

                    {index == 1 ?
                        /*
                        *
                        * =================================================================================================================
                        * NASTAVENIA
                        * =================================================================================================================
                        *
                        */

                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight }}>
                            {loaded != false ?
                                <div>
                                    <FormSpace />
                                    <div style={{ ...styles.Block }}>
                                        <Avatar editable={true} picture image={avatar} size={100} theme={props.theme} func={AvatarPress.bind()} />
                                        <p style={{ ...styles.TextTiny, marginTop: 5, color: color.dark_gray }}>{lang.album_image}</p>
                                    </div>
                                    <FormSpace />
                                    <Line theme={props.theme} />
                                    <FormSpace />
                                    <FormSelect value={stateID} items={states} field={'name'} editing={true} title={lang.album_country} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(id) => setStateID(id)} />
                                    <FormText require redraw numeric value={years} editing={true} title={lang.album_years} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setYears(txt)} />
                                    <FormSpace />
                                    <FormText redraw value={note} editing={true} title={lang.note} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setNote(txt)} />
                                </div>
                                :
                                <Stack spacing={1}>
                                    <FormSpace />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                    <Skeleton variant="rounded" animation="wave" width={column1 + column2} height={36} />
                                </Stack>}
                        </div>
                        : null}


                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {index == 1 ?
                                <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok, marginRight: 10 }}>{lang.save}</Button>
                                : null}
                            <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel, marginLeft: 10 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div >

                {showAskExport == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.stat_export_ask} text={lang.stat_export_ask_text} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={Export.bind(this)} />
                    : null}

                {showStamp == true ?
                    <Stamp item={stampSelected} album_id={itemID} year={years} page_uid={pageUID} state_id={stateID} organization={props.organization} user={props.user} permissions={props.permissions} system={props.system} lang={props.lang} language={props.language} theme={props.theme} offset={props.offset} radius={props.radius} precision={props.precision} isSmall={props.isSmall} title={lang.cond_designer} token={props.token} func={StampResult.bind()} />
                    : null}

                {selectPhoto == true ?
                    <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                    : null}

                {showOK == true ?
                    <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


