/*
*
* =================================================================================================================
* SK - LANGUAGE
* =================================================================================================================
*
*/

import { faCog, faHome, faKeyboard, faLayerGroup, faP, faPerson, faShoppingCart, faT, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";

export const sk = {

    // **********************************************************************    
    // APLIKACIA
    // **********************************************************************

    title: 'Vision CRM',
    title_welcome: 'Vitajte v systéme Vision CRM',
    region: 'Krajina',
    language: 'SK',
    state: 'Štát',

    // **********************************************************************    
    // STRUKTURA MENU
    // **********************************************************************

    menu: [
        {
            id: 0,
            enabled: true,
            name: 'Domov',
            label: 'Domov',
            icon: faHome,
            color: '#FFFFFF',
        },
        /*
        *
        * =================================================================================================================
        *   ZAKAZNICI
        * =================================================================================================================
        * 
        */

        {
            id: 30,
            enabled: true,
            name: 'Filatelia',
            label: 'Filatelia',
            admin_only: false,
            icon: faUser,
            color: '#70FF70',
            children: [
                {
                    id: 300,
                    enabled: true,
                    admin_only: false,
                    name: 'Zoznam ročníkov',
                    label: 'Zoznam ročníkov',
                },
                {
                    id: 301,
                    enabled: true,
                    admin_only: false,
                    name: 'Štatistika',
                    label: 'Štatistika',
                },
            ]
        },

        /*
        *
        * =================================================================================================================
        *   NASTAVENIE
        * =================================================================================================================
        *
        */
        {
            id: 9000,
            enabled: true,
            admin_only: false,
            name: 'Nastavenie',
            label: 'Nastavenie aplikácie',
            icon: faCog,
            color: '#44AAFF',
            children: [
                {
                    id: 9006,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    name: 'Užívateľ'
                },
                {
                    id: 9005,
                    enabled: true,
                    reload: true,
                    admin_only: false,
                    name: 'Môj profil',
                    label: 'Môj profil',
                },
                {
                    id: 9007,
                    // ===== LINE =====
                    divider: true,
                    enabled: true,
                    name: 'Systém'
                },
                {
                    id: 9004,
                    enabled: true,
                    reload: true,
                    permission_column: 'system_logs',
                    admin_only: true,
                    name: 'Systémové logy',
                    label: 'Systémové logy',
                },
            ]
        },

    ],

    // **********************************************************************    
    // PRIHLASENIE DO APLIKACIE
    // **********************************************************************

    login: 'Prihlásenie',
    login_email: 'E-mailová adresa',
    loginname: 'Prihlasovacie meno (e-mail)',
    loginname_error: 'Prihlasovanie meno musí byť e-mailová adresa',
    login_data: 'Prihlasovacie údaje',
    email_error: 'Nesprávny formát e-mailovej adresy',
    username: 'Prihlasovacie meno',
    password: 'Heslo',
    password_login: 'Prihlasovanie heslo',
    password_old: 'Pôvodné heslo',
    password_old_err: 'Pôvodné heslo je nesprávne',
    password_: 'Heslo (minimálna dĺžka 8 znakov)',
    password_new: 'Nové heslo (minimálne 8 znakov)',
    password_new_account: 'Vytvorte si nové heslo (minimálne 8 znakov)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadané heslá sa nezhodujú',
    password_ok: 'Heslo bolo úspešne zmenené',
    password_error: 'Pri zmene hesla nastala chyba. Skúste požiadavku opakovať.',
    password_error_len: 'Minimálna dĺžka hesla musí byť 8 znakov',
    password_change: 'Zmeniť heslo',
    password_change_: 'Zmena hesla',
    password_strong: 'Sila hesla',
    password_change_login: 'Změna přihlašovací hesla do aplikace',
    password_change_login: 'Zmena prihlasovacie hesla do aplikácie',
    passwords: ['Nepostačujúce', 'Slabé', 'Priemerné', 'Bezpečné'],
    login_button: 'Prihlásiť sa',
    lost_password_button: 'Zabudli ste heslo?',
    login_error: 'Nesprávne prihlasovacie údaje!',
    lost_password: 'Zabudnuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa sa nenachádza v systéme',
    login_pin_code_error: 'Nesprávny overovací kód',
    login_pin_code: 'Zadajte kód z e-mailu',
    login_email_send: 'Na Vašu e-mailovú adresu sme zaslali overovací kód. Skontrolujte aj spamový adresár.',
    password_changed: 'Zmena hesla prebehla úspešne',
    logout_title: 'Odhlásiť sa',
    logout_ask: 'Chcete sa odhlásiť z aplikácie?',
    email_exists: 'Zadaná e-mailová adresa už je registrovaná v CRM. Zadajte inú adresu.',
    link_error: 'Error 404 - Neplatný link',
    module: 'Modul',
    modules: 'Moduly',
    system: 'Systém',
    appearance: 'Vzhľad',
    preview_mode: 'Náhľadový mód',
    number: 'Číslo',
    no_name: 'Bez názvu',

    register: 'Registrácia',
    register_: 'Registrovať',
    register_new: 'Nová registrácia',

    // **********************************************************************    
    // SYSTEM
    // **********************************************************************

    db_error: 'Pri získavaní informácii z DB nastala chyba!',
    db_error_text: 'Skúste požiadavku opakovať.',
    enabled: 'Aktívny',
    enabled_: 'Akt.',
    disabled: 'Neaktívny',
    close: 'Zavrieť',
    ok: 'OK',
    back: 'Späť',
    continue: 'Pokračovať',
    choose: 'Výber',
    choose_: 'Vybrať',
    save: 'Uložiť',
    cancel: 'Zrušiť',
    required: 'Povinný údaj',
    yes: 'Áno',
    no: 'Nie',
    node_root: 'CEO',
    node_parent: 'Vlastník',
    node_name: 'Pozícia',
    node_name_: 'Názov pozície',
    node_share: 'Zdielať údaje',
    node_share_: 'Zdielať údaje s kolegami',
    node_share_text: 'Údaje budú zdelané s kolegami v rámci jednej pozície',
    node_editing: 'Úprava uživateľských pozícií',
    node_edit: 'Úprava pozície',
    calculator: 'Kalkulačka',
    textfield_calc_1: 'Toto textové pole pracuje aj ako kalkulačka',
    textfield_calc_2: 'napr. (100 + 50) * 2.1',
    required_red: 'Textové polia označené červenou farbou sú povinné',
    search: 'Hľadať',
    search_filter: 'Hľadať podľa',
    empty_list: 'Žiadne položky',
    created: 'Vytvorené',
    updated: 'Upravené',
    updated_by: 'Upravil',
    edit: 'Upraviť',
    app_language: 'Jazyk aplikácie',
    app_country: 'CRM krajina',
    app_country_change: 'Možnosť zmeniť krajinu',
    info: 'Informácie',
    info_: 'Informácia',
    delete: 'Vymazať',
    credit: 'Kredit',
    credits: 'Kredity',
    credits_: 'Kreditov',
    credit_price: 'Cena kreditu',
    buy: 'Kúpiť',
    sms_code: 'SMS kód',
    sms_code_error: 'Nesprávny kód',
    sms_code_last_try: 'Posledný pokus',
    sms_code_sended: 'Zadajte overovací SMS kód, ktorý sme vám odoslali na váš mobil.',
    ip_address: 'IP adresa',
    date: 'Dátum',
    order: 'Objednávka',
    orders: 'Objednávky',
    application: 'Aplikácia',
    error: 'Chyba',
    sms_error_text1: 'Nepodarilo sa odoslať SMS správu',
    sms_error_text2: 'Skúste požiadavku opakovať, alebo sa poraďte s administrátorom.',
    setting: 'Nastavenie',
    settings: 'Nastavenia',
    history: 'História',
    logs_history: 'História vytváraných zmien',
    logs_status: [
        'vytvorené',
        'upravené',
        'vymazané',
        'stav zmenený na aktívne',
        'stav zmenený na neaktívne',
        'variácia vytvorená',
        'variácia upravená',
        'stav variácie zmenený na aktívna',
        'stav variácie zmenený na neaktívna',
        'zmena cien',
        'zmena nákupnej ceny',
        'zmena predajnej ceny',
        'zmena cien variácie',
        'zmena nákupnej ceny variácie',
        'zmena predajnej ceny variácie',
    ],
    default_image: 'Profilový obrázok',
    app_error: 'Pri vstupe do aplikácie nastala chyba. Skontrolujte internetové pripojenie a reštartujte aplikáciu!',
    internet_error: 'Zdá sa, že nie ste pripojený k internetu.',
    internet_error_text: 'Skontrolujte internetové pripojenie a reštartujte aplikáciu!',
    export: 'Exportovať',
    export_pdf: 'Exportovať do PDF',
    export_xls: 'Exportovať do XLS',
    export_all: 'Exportovať všetko',
    export_selected: 'Exportovať označené',
    export_note: 'Poznámka k exportu údajov',
    export_deleting: 'Platnosť súborov na stiahnutie je 7 dní',
    export_files: 'Export súborov',
    export_columns: 'Vyberte stĺpce, ktoré sa budú exportovať',
    sorting: 'Triedenie',
    ordering: 'Zoraďovanie',
    items_count: 'Počet položiek',
    file: 'Súbor',
    files: 'Súbory',
    download: 'Stiahnúť súbor',
    downloaded: 'Stiahnutý súbor',
    close_all: 'Zavrieť všetko',
    open_all: 'Otvoriť všetko',
    open: 'Otvoriť',
    hide_decimal: 'Nezobrazovať desatinné miesta',
    show_decimal: 'Zobrazovať desatinné miesta',
    hide_manufacture: 'Nezobrazovať výrobcu',
    show_manufacture: 'Zobrazovať výrobcu',
    date_include: 'Dátum vrátane zvoleného dňa',
    piece: 'ks',
    tags: 'Kľučové slová',
    tags_text: 'Jednotlivé slová oddeľujte medzerou',
    undefined: 'Nedefinovaný',
    batch_update: 'Dávková úprava',
    show_variations: 'Zobrazovať variácie',
    hide_variations: 'Nezobrazovať variácie',
    temporary: 'Dočasná',
    temporary_: 'Dočasný',
    export_none_error: 'Nie sú označené žiadne položky',
    export_none_error_: 'Označte položky, ktoré budú exportované',
    change: 'Zmena',
    changes: 'Zmeny',
    reset: 'Reset',
    text_percentage: 'Percentá / Koeficient',
    text_percentage_text_1: 'Vklad koeficientu',
    text_percentage_text_2: 'Koeficient napr. 1.10 znamená +10%, 0.95 znamená -5%',
    text_percentage_text_3: 'Vklad percent - musí byť použitý znak % na konci',
    text_percentage_text_4: 'Percená napr. +10%, -5%',
    new_record: 'Nový záznam',
    automatic: 'Automatické',
    manual: 'Manuálne',
    code_name: 'Názov kódu',
    bytes_left: 'Zostáva @byte Bytes na vloženie údajov',
    bytes_left_out: 'Na vloženie údajov nie je dostatok miesta',
    insert_press_enter: 'Po zadaní stlačne "Enter"',
    delete_item_ask: 'Vymazať položku?',
    cancel_filters: 'Zrušiť filtre',
    filters: 'Filtrovanie záznamov',
    status: 'Stav',
    count: 'Počet',
    label: 'Názov',
    system_match: 'Podľa systémového nastavenia',
    priority: 'Poradie',
    priority_up: 'Presunúť vyššie',
    priority_down: 'Presunúť nižšie',
    money: '€',

    // **********************************************************************    
    // CALENDAR
    // **********************************************************************

    year: 'Rok',
    month: 'Mesiac',
    day: 'Deň',

    // **********************************************************************    
    // NASTAVENIE ORGANIZACIE / APLIKACIE
    // **********************************************************************

    organization: 'Organizácia',
    organization_settings: 'Nastavenie organizácie',
    organization_name: 'Názov',
    organization_name_: 'Názov spoločnosti',
    organization_info: 'Informácie o organizácii',
    organization_verified: 'Registrácia v CRM bola úspešne overená',
    organization_to_crm: 'Prejsť do CRM',

    app_settings: 'Nastavenie aplikácie',
    app_customize: 'Prispôsobenie',
    application: 'Aplikacia',
    application_customize: 'Prispôsobenie aplikácie',
    customize_name: 'Zobrazovanie mena',
    customize_name_1: 'Meno Priezvisko',
    customize_name_2: 'Priezvisko Meno',

    system_settings: 'Nastavenie systému CRM',
    system_precision: 'Počet desatiných miest',
    system_precision_note: 'Počet desatiných miest zobrazovaných v cenách',
    system_precision_1: '2 desatiné miesta',
    system_precision_2: '3 desatiné miesta',
    system_price_creator: 'Tvorba predajných cien',
    system_price_coeficient: 'Koeficient výpočtu ceny',
    system_price_coeficient_enable: 'Prepočet cez koeficient',
    system_price_coeficient_note: 'Predajná cena sa vypočíta (nákupná cena * koeficient)',
    system_price_coeficient_category_note: 'Výpočet predajnej ceny cez koeficient pre celú kategóriu',

    // **********************************************************************    
    // USER / ORGANIZATION / COMPANY
    // **********************************************************************

    user: 'Užívateľ',
    users: 'Užívatelia',
    user_enabled: 'Aktívny',
    users_list: 'Zoznam užívateľov',
    users_groups: 'Skupiny',
    users_group: 'Skupina',
    users_group_new: 'Nová skupina',
    users_groups_: 'Skupiny užívateľov',
    users_roles: 'Pozície',
    users_roles_: 'Uživateľské pozície',
    users_roles_text: 'Na tejto stránke môžete nadefinovať ako budú zdieľané údaje medzi jednotlivými užívateľmi v rámci jednej organizácie.',
    users_role: 'Pozícia',
    user_new: 'Nový užívateľ',
    user_info: 'Informácie o užívateľovi',
    user_register: 'Registrácia nového užívateľa',
    user_groups: 'Užívatelské skupiny',
    user_groups_text: 'Skupiny dovoľujú radenie jednotlivých užívateľov do skupín.',
    user_group_enabled: 'Aktívna skupina',
    user_group_label: 'Názov skupiny',
    user_group_info: 'Informácie',
    user_group_edit: 'Úprava skupiny',
    users_group_list: 'Zoznam užívateľov v skupine',
    users_groups_all: 'Všetky skupiny',
    super_admin: 'Super admin',
    user_invite: 'Odoslať pozvánku',
    users_add: 'Pridať užívateľov',
    user_select: 'Výber užívateľa',
    company_name: 'Názov firmy',

    basic_info: 'Základné informácie',
    contact_info: 'Kontaktné údaje',
    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    alias: 'Alias',
    address: 'Adresa',
    street: 'Ulica',
    psc: 'PSČ',
    town: 'Mesto',
    state: 'Štát',
    birtdate: 'Dátum narodenia',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ DPH',
    bank: 'Názov banky',
    iban: 'IBAN',
    swift: 'SWIFT',
    currency: 'Platobná mena',
    phone: 'telefón',
    mobil: 'mobil',
    mobil_international: 'Číslo musí byť v medzinárodnom tvare (+421...)',
    email: 'e-mail',
    email_address: 'e-mailová adresa',
    url: 'Webová adresa',
    note: 'Poznámka',
    firm_data: 'Firemné údaje',
    contact_data: 'Kontaktné údaje',
    dph_pay: 'Platca dph',

    user_profile: 'Môj profil',
    user_profile_info: 'Základné informácie',
    user_profile_login: 'Prihlasovanie do aplikácie',
    user_profile_2fa: 'Dvojfaktorové overenie',
    user_profile_2fa_note: 'Dvojfaktorové overenie pri prihlasovaní cez SMS správu',
    user_profile_mobil_verify: 'Overenie mobilného čísla',
    user_profile_mobil_text: 'Pre spustenie dvojfaktorového overovania cez SMS je potrebné overenie mobilného čísla.',
    user_profile_mobil_number: 'Na vaše mobilné číslo @mobil bude odoslaný verifikačný kód',
    user_profile_mobil_number_send: 'Odoslať kód',
    user_profile_mobil_number_cancel: 'Vypnúť overovanie',
    user_profile_mobil_verified: 'Overené číslo',

    // **********************************************************************    
    // FOTOGRAFIE
    // **********************************************************************

    photos: 'Fotografie',
    photo: 'Fotografia',
    photo_select_title: 'Výber a úprava fotografie',
    photo_select: 'Vybrať fotografiu',
    photo_change: 'Zmeniť fotografiu',
    photo_delete: 'Vymazať',
    photo_delete_text: 'Chcete vymazať fotografiu?',
    photo_save: 'Vložiť',
    photo_min: 'Minimálna veľkosť',

    // **********************************************************************    
    // SYSTEM LOGS
    // **********************************************************************

    system_logs: 'Systémové logy',
    logs_logins: 'Prihlasovanie',
    logs_cost_changed: 'Zmena nákupnej ceny z @cost_from @cur na @cost_to @cur',
    logs_price_changed: 'Zmena predajnej ceny z @cost_from @cur na @cost_to @cur',

    codebook: 'Číselník',
    codebooks: 'Číselníky',
    codebook_add: 'Nová položka',
    codebook_item_exists: 'Položka sa už nachádza v zozname!',


    // **********************************************************************    
    // FILATELIA
    // ********************************************************************** 

    filatelia: 'Filatelia',


    // **********************************************************************    
    // ALBUMY
    // ********************************************************************** 

    albums: 'Ročníky',
    album: 'Ročník',
    album_new: 'Nový ročník',
    album_name: 'Názov ročníka',
    album_years: 'rok',
    album_image: 'Obrázok ročníka',
    album_country: 'Štát',
    album_pages: 'Počet strán v ročníku',
    album_all_countries: 'Všetky štáty',
    album_search: 'Hľadať podľa roku',
    album_settings: 'Nastavenia',
    album_stamps: 'Známky v ročníku',
    album_info: 'Informácie o ročníku',
    album_pages: 'Listy',
    album_pages_list: 'Zoznam listov v ročníku',
    album_page_stamps: 'Zoznam známok na liste',
    album_page_search: 'Hľadať list',
    album_pages_count: 'Počet listov v ročníku',
    album_stamps_count: 'Počet známok',
    album_list_stamp_count: 'Počet známok',

    // **********************************************************************    
    // ZNAMKY
    // ********************************************************************** 

    stamps: 'Známky',
    stamp: 'Známka',
    stamp_new: 'Pridať známku',
    stamp_new_: 'Nová známka',
    stamp_image: 'Obrázok známky',
    stamp_info: 'Informácie o známke',
    stamp_album_list: 'Zoznam známok v ročníku',
    stamp_name: 'Názov známky',
    stamp_prices: 'Nominálna hodnota známky',
    stamp_prices_: 'Cena známky',
    stamp_price: 'Nominálna hodnota',
    stamp_money: 'Peňažná mena',
    stamp_code: 'Číslo známky',
    stamp_page: 'Číslo listu',
    stamp_cost: 'Nadobúdajúca cena',
    stamp_cost_note: 'Cena, za ktorú bola známka zakúpená',
    stamp_price_catalogue: 'Katalógová cena',
    stamp_date_catalogue: 'Dátum katalógovej ceny',
    stamp_price_market: 'Trhová cena',
    stamp_search: 'Hľadať známku',

    // **********************************************************************    
    // ŠTATISTIKA
    // **********************************************************************    

    stat: 'Štatistika',
    stats: 'Štatistiky',
    stat_choose: 'Vybrať štát',
    stat_export: 'Exportovať',
    stat_export_note: 'Exportovať ročník do súboru PDF',
    stat_export_ask: 'Export ročníku',
    stat_export_ask_text: 'Chcete exportovať ročník do súboru PDF?',
    pdf_show: 'Zobraziť PDF',
}
