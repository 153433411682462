/*
*
* =================================================================================================================
* PROFIL UŽÍVATELA

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <MyProfile organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Avatar, FormError, FormLabel, Icon, FormText, FormYesNo, Line, Loading, ShowError, ShowOK, FormSpace, FormNote, FormSelect, DialogYesNo, DialogEnterPin, DialogInfo, FormButton, DialogPasswordChange } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faExclamationTriangle, faInfoCircle, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetTodayYear, Today } from './functions_date';
import { Debug, IsEmpty } from './functions';
import { Photos } from './photos';

export const MyProfile = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let permissions = global.permissions;
    let color = global.themes[props.theme];

    // IMAGES
    const user_default = require('./react/app/user.png');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA
    const [storeData, setStoreData] = useState(false);

    const [id, setID] = useState(props.user.id);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [mobil, setMobil] = useState('');
    const [email, setEmail] = useState('');
    const [themeID, setThemeID] = useState(0);
    const [avatar, setAvatar] = useState('');

    // DIALOGS
    const [showVerify, setShowVerify] = useState(false);
    const [showEnterPin, setShowEnterPin] = useState(false);
    const [showPinError, setShowPinError] = useState(false);
    const [showSelectPhoto, setSelectPhoto] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const column1 = 200;
    const column2 = 350;
    const offset = 20;

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get();

            running = true;
        }

    }, []);

    useEffect(() => {
    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // ------------------------------------
                    id: props.user.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                let item = json.user;
                setStoreData(item);
                setAvatar(item.avatar);

                if (item != false) {
                    AssignData(item);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    organization_id: props.organization.id,
                    user_id: props.user.id,
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setEditing(false);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const AssignData = (item) => {
        // priradenie údajov pri načítaní z DB
        setName(item.name);
        setSurname(item.surname);
        setMobil(item.mobil);
        setEmail(item.email);
        setAvatar(item.avatar);
    }

    const CancelEditing = () => {
        // Nahrat povodne údaje - bolo stlačené [Zrušiť]
        AssignData(storeData);
        setEditing(false);
    }

    const StoreEditing = () => {
        // Uloziť upravené údaje do pameti
        storeData.name = name;
        storeData.surname = surname;
        storeData.email = email;
        storeData.mobil = mobil;
        storeData.avatar = avatar;
    }

    const GetData = () => {
        // získanie údajov do reťazca
        let data = {
            id: id,
            language_id: 1,
            name: name.trim(),
            surname: surname.trim(),
            email: email.trim(),
            mobil: mobil.trim(),
            theme_id: 0,
            avatar: avatar,
        }

        return data;
    }

    const Save = () => {
        setError('');

        // údaje sú OK - uložiť
        let data = GetData();

        StoreEditing();
        db_update(data);

    }

    const FormLabelResult = (value) => {
        if (value == 0) {
            setEditing(true);
        }
    }

    const AvatarPress = () => {
        setSelectPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);

            let data = {
                id: id,
                avatar: path + file
            }
            db_update(data);
        }
    }

    const ChangePassword = (value) => {
        if (value == true) {
            setShowChangePassword(true);
        }
    }

    const PasswordResult = (typ, value) => {
        setShowChangePassword(false);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faUser} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, paddingBottom: global.list_padding, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                    {storeData != false ?
                        <Paper elevation={global.elevation_form} style={{ ...styles.BlockCenter, width: column1 + column2 + (2 * offset), marginTop: 20, paddingTop: offset, paddingBottom: offset * 2, backgroundColor: color.white, borderRadius: props.radius }}>
                            <Avatar editable image={avatar} lang={props.lang} theme={props.theme} func={AvatarPress.bind()} />
                            <FormSpace height={30} />

                            <FormLabel editing={editing} icon={faInfoCircle} editButton={true} title={lang.user_profile_info} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={FormLabelResult.bind(this)} />
                            <Line color={color.light_gray} />

                            {/* INFORMACIE */}
                            <FormText require value={name} editing={editing} title={lang.name} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setName(txt)} />
                            <FormText require value={surname} editing={editing} title={lang.surname} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setSurname(txt)} />
                            <FormSpace />
                            <FormText require value={email} editing={editing} title={lang.email} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setEmail(txt)} />
                            <FormText phone value={mobil} editing={editing} title={lang.mobil} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={(txt) => setMobil(txt)} />
                            <FormSpace />


                            {/* ZMENA PRIHLASOVACIE HESLA */}
                            <FormLabel editing={editing} title={lang.password_login} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} marginTop={30} func={FormLabelResult.bind(this)} />
                            <Line theme={props.theme} />
                            <FormButton value={'********'} editing={editing} title={lang.password} column1={column1} column2={column2} theme={props.theme} lang={lang} radius={props.radius} func={ChangePassword.bind(this)} />
                            <FormNote title={lang.password_change_login} column1={column1} column2={column2} marginTop={2} theme={props.theme} lang={lang} radius={props.radius} />


                            {/* SAVE - CANCEL */}
                            {editing == true ?
                                <div style={{ ...styles.Block, paddingTop: 10 }}>
                                    <FormError theme={props.theme} error={error} small />

                                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <Button onClick={() => Save()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_ok }}>{lang.save}</Button>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <Button onClick={() => CancelEditing()} style={{ ...styles.ButtonThemed, backgroundColor: color.button_cancel }}>{lang.cancel}</Button>
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </Paper>
                        : null}

                </div>
            </div>

            {showSelectPhoto == true ?
                <Photos autoSelect typ={global.image.others} max_size={600} width={600} height={600} title={lang.photo_select} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} radius={props.radius} token={props.token} func={PhotoResult.bind(this)} />
                : null}

            {showChangePassword == true ?
                <DialogPasswordChange radius={props.radius} title={'nazov'} sub_title={'podnázov'} organization={props.organization} user={props.user} theme={props.theme} lang={props.lang} offset={props.offset} token={props.token} func={PasswordResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


