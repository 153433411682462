/*
*
* =================================================================================================================
* SK - LANGUAGE
* =================================================================================================================
*
*/

import { faHome } from "@fortawesome/free-solid-svg-icons";

export const cz = {

    // **********************************************************************    
    // APLIKACIA
    // **********************************************************************

    title: 'Vision CRM',
    title_welcome: 'Vítejte v systému Vision CRM',
    region: 'Země',
    language: 'CZ',
    state: 'Stát',

    // **********************************************************************    
    // STRUKTURA MENU
    // **********************************************************************

    menu: [
        {
            id: 0,
            enabled: true,
            name: 'Domov',
            label: 'Domov',
            icon: faHome,
            color: '#0000FF',
            icon_color: '#FFFFFF',
        },
    ],


    // **********************************************************************    
    // PRIHLASENIE DO APLIKACIE
    // **********************************************************************

    login: 'Přihlášení',
    login_email: 'E-mailová adresa',
    loginname: 'Přihlašovací jméno (e-mail)',
    loginname_error: 'Přihlašování jméno musí být e-mailová adresa',
    login_data: 'Přihlašovací údaje',
    email_error: 'Nesprávný formát e-mailové adresy',
    username: 'Přihlašovací jméno',
    password: 'Heslo',
    password_login: 'Přihlašování heslo',
    password_old: 'Původní heslo',
    password_old_err: 'Původní heslo je nesprávné',
    password_: 'Heslo (minimální délka 8 znaků)',
    password_new: 'Nové heslo (minimálně 8 znaků)',
    password_new_account: 'Vytvořte si nové heslo (minimálně 8 znaků)',
    password_new_again: 'Zopakujte nové heslo',
    passwords_not_equal: 'Zadaná hesla se neshodují',
    password_ok: 'Heslo bylo úspěšně změněno',
    password_error: 'Při změně hesla nastala chyba. Zkuste požadavek opakovat.',
    password_error_len: 'Minimální délka hesla musí být 8 znaků',
    password_change: 'Změnit heslo',
    password_change_: 'Změna hesla',
    password_strong: 'Síla hesla',
    password_change_login: 'Change login password to the application',
    passwords: ['Nepostačující', 'Slabé', 'Průměrné', 'Bezpečné'],
    login_button: 'Přihlásit se',
    lost_password_button: 'Zapomněli jste heslo?',
    login_error: 'Nesprávné přihlašovací údaje!',
    lost_password: 'Zapomenuté heslo',
    lost_login_error: 'Zadaná e-mailová adresa se nenachází v systému',
    login_pin_code_error: 'Nesprávný ověřovací kód',
    login_pin_code: 'Zadejte kód z e-mailu',
    login_email_send: 'Na Vaši e-mailovou adresu jsme zaslali ověřovací kód. Zkontrolujte také spamový adresář.',
    password_changed: 'Změna hesla proběhla úspěšně',
    logout_title: 'Odhlásit se',
    logout_ask: 'Chcete se odhlásit z aplikace?',
    email_exists: 'Zadaná e-mailová adresa už je registrovaná v CRM. Zadejte inú adresu.',
    link_error: 'Error 404 - Neplatný link',
    module: 'Modul',
    modules: 'Moduly',
    system: 'Systém',
    appearance: 'Vzhled',
    preview_mode: 'Náhledový mód',
    number: 'Číslo',
    no_name: 'Bez názvu',

    register: 'Registrace',
    register_: 'Registrovat',
    register_new: 'Nová registrace',

    // **********************************************************************    
    // CALENDAR
    // **********************************************************************

    year: 'Rok',
    month: 'Měsíc',
    day: 'Den',

    // **********************************************************************    
    // SYSTEM
    // **********************************************************************

    db_error: 'Při získávání informací z DB nastala chyba!',
    db_error_text: 'Zkuste požadavek opakovat.',
    enabled: 'Aktivní',
    enabled: 'Akt.',
    disabled: 'Neaktivní',
    close: 'Zavřít',
    ok: 'OK',
    back: 'Zpět',
    continue: 'Pokračovat',
    choose: 'Výběr',
    choose_: 'Vybrat',
    save: 'Uložit',
    cancel: 'Zrušit',
    required: 'Povinný údaj',
    yes: 'Ano',
    no: 'Ne',
    node_root: 'CEO',
    node_parent: 'Vlastník',
    node_name: 'Pozice',
    node_name_: 'Název pozice',
    node_share: 'Sdílet údaje',
    node_share_: 'Sdílet údaje s kolegy',
    node_editing: 'Úprava uživatelských pozic',
    node_edit: 'Úprava pozice',
    calculator: 'Kalkulačka',
    textfield_calc_1: 'Toto textové pole pracuje také jako kalkulačka',
    textfield_calc_2: 'např. (100 + 50) * 2.1',
    required_red: 'Textová pole označená červenou barvou jsou povinná',
    search: 'Hledat',
    search_filter: 'Hledat podle',
    empty_list: 'Žádné položky',
    created: 'Vytvořeno',
    updated: 'Upraveno',
    updated_by: 'Upravil',
    edit: 'Adjust',
    app_language: 'Jazyk aplikace',
    app_country: 'CRM země',
    app_country_change: 'Možnost změnit zemi',
    info: 'Informace',
    info_: 'Informace',
    delete: 'Vymazat',
    credit: 'Kredit',
    credits: 'Kredity',
    credits_: 'Kreditů',
    credit_price: 'Cena kreditu',
    buy: 'Koupit',
    sms_code: 'SMS kód',
    sms_code_error: 'Nesprávný kód',
    sms_code_last_try: 'Poslední pokus',
    sms_code_sended: 'Zadejte ověřovací SMS kód, který jsme vám odeslali na váš mobil.',
    ip_address: 'IP adresa',
    date: 'Datum',
    order: 'Objednávka',
    orders: 'Objednávky',
    application: 'Aplikace',
    error: 'Chyba',
    sms_error_text1: 'Nepodařilo se odeslat SMS zprávu',
    sms_error_text2: 'Zkuste požadavek opakovat, nebo se poraďte s administrátorem.',
    setting: 'Nastavení',
    settings: 'Nastavení',
    history: 'Historie',
    logs_history: 'Historie vytvářených změn',
    logs_status: [
        'vytvořeno',
        'upraveno',
        'vymazáno',
        'stav změněn na aktivní',
        'stav změněn na neaktivní',
        'variace vytvořená',
        'variace upravená',
        'stav variace změněn na aktivní',
        'stav variace změněn na neaktivní',
        'změna cen',
        'změna nákupní ceny',
        'změna prodejní ceny',
        'změna cen variace',
        'změna nákupní ceny variace',
        'změna prodejní ceny variace',
    ],
    default_image: 'Profilový obrázek',
    app_error: 'Při vstupu do aplikace nastala chyba. Zkontrolujte internetové připojení a restartujte aplikaci!',
    internet_error: 'Zdá se, že nejste připojeni k internetu.',
    internet_error_text: 'Zkontrolujte internetové připojení a restartujte aplikaci!',
    export: 'Exportovat',
    export_pdf: 'Exportovat do PDF',
    export_xls: 'Exportovat do XLS',
    export_all: 'Exportovat vše',
    export_selected: 'Exportovat označené',
    export_note: 'Poznámka k exportu dat',
    export_deleting: 'Platnost souborů ke stažení je 7 dní',
    export_files: 'Export souborů',
    export_columns: 'Vyberte sloupce, které budou exportovány',
    sorting: 'Třídění',
    ordering: 'Seřizování',
    items_count: 'Počet položek',
    file: 'Soubor',
    files: 'Soubory',
    download: 'Stáhnout soubor',
    downloaded: 'Stažen soubor',
    close_all: 'Zavřít všechno',
    open_all: 'Otevřít všechno',
    open: 'Otevřít',
    hide_decimal: 'Nezobrazovat desetinná místa',
    show_decimal: 'Zobrazovat desetinná místa',
    hide_manufacture: 'Nezobrazovat výrobce',
    show_manufacture: 'Zobrazovat výrobce',
    date_include: 'Datum včetně zvoleného dne',
    piece: 'ks',
    tags: 'Klíčová slova',
    tags_text: 'Jednotlivá slova oddělujte mezerou',
    undefined: 'Nedefinován',
    batch_update: 'Dávková úprava',
    show_variations: 'Zobrazovat variace',
    hide_variations: 'Nezobrazovat variace',
    temporary: 'Dočasná',
    temporary_: 'Dočasný',
    export_none_error: 'Nejsou označeny žádné položky',
    export_none_error_: 'Označte položky, které budou exportovány',
    change: 'Změna',
    changes: 'Změny',
    reset: 'Reset',
    text_percentage: 'Procenta / Koeficient',
    text_percentage_text_1: 'Vklad koeficientu',
    text_percentage_text_2: 'Koeficient nap. 1.10 znamená +10%, 0.95 znamená -5%',
    text_percentage_text_3: 'Vklad procent - musí být použit znak % na konci',
    text_percentage_text_4: 'Perceně např. +10%, -5%',
    new_record: 'Nový záznam',
    automatic: 'Automatické',
    manual: 'Manuální',
    code_name: 'Název kódu',
    bytes_left: 'Zůstává @byte Bytes pro vložení dat',
    bytes_left_out: 'Na vložení dat není dostatek místa',
    insert_press_enter: 'Po zadání stisknete "Enter"',
    delete_item_ask: 'Vymazat položku?',
    cancel_filters: 'Zrušit filtry',
    filtry: 'Filtrování záznamů',
    status: 'Stav',
    count: 'Počet',
    label: 'Název',
    system_match: 'Podle systémového nastavení',
    priority: 'Pořadí',
    priority_up: 'Přesunout výše',
    priority_down: 'Přesunout níže',

    // **********************************************************************    
    // CALENDAR
    // **********************************************************************

    year: 'Rok',
    month: 'Měsíc',
    day: 'Den',

    // **********************************************************************    
    // CONDITIONS - PODMIENKY
    // **********************************************************************

    cond: 'Podmínka',
    conds: 'Podmínky',
    cond_designer: 'Tvorba podmínek',
    cond_next: 'Další podmínka',
    cond_exe: 'Spuštění podmínky',
    cond_true: 'Splněná podmínka',
    cond_false: 'Nesplněná podmínka',
    cond_yes: 'Ano',
    cond_no: 'Ne',
    cond_result: 'Výsledek',
    cond_result_condition: 'Výsledek podmínky',
    cond_pattern: 'Vzor vyhodnocování',
    cond_undefined: 'Nedefinován',
    cond_and: 'A',
    cond_or: 'Nebo',
    cond_include: 'Obsahuje',
    cond_starts: 'Začíná',
    cond_ends: 'Končí',
    cond_name: 'Název podmínky',

    cond_read: 'Při načítání údajů',
    cond_write: 'Při zápisu dat',
    cond_choose: 'Výběr',
    cond_error: 'Nesprávně vyplněné údaje',
    cond_result_error: 'Hodnoty výsledné proměnné nejsou definovány',
    cond_condition_error: 'Podmínka nesmí být prázdná',

    cond_product_quantity: 'Počet kusů',
    cond_product_label: 'Název produktu',
    cond_product_enabled: 'Produkt aktivní',
    cond_date: 'Datum',
    cond_enabled: 'Aktivní',
    cond_price: 'Cena',
    cond_time: 'Čas',
    cond_email: 'E-mail',
    cond_stock: 'Skladem',

    cond_new: 'Nová podmínka',
    cond_update: 'Úprava podmínky',
    cond_note: 'Vytvoření podmíněného zobrazování produktu',
    cond_active: 'Podmínka - aktivní',
    cond_delete_ask: 'Vymazat podmínku?',
    cond_copy_value: '"..." původní hodnota',
    cond_used: 'Využití',
    cond_used_items: 'Využití podmínky',

    // **********************************************************************    
    // NASTAVENIE ORGANIZACIE
    // **********************************************************************

    organization: 'Organizace',
    organization_settings: 'Nastavení organizace',
    organization_name: 'Název',
    organization_name_: 'Název společnosti',
    organization_info: 'Informace o organizaci',
    app_settings: 'Nastavení aplikace',
    app_customize: 'Přizpůsobení',
    application: 'Aplikace',
    application_customize: 'Přizpůsobení aplikace',

    system_settings: 'Nastavenie systému CRM',
    system_precision: 'Počet desatinných miest',
    system_precision_note: 'Počet desatinných miest zobrazovaných v cene',
    system_precision_1: '2 desatinné miesta',
    system_precision_2: '3 desatinné miesta',
    system_price_creator: 'Tvorba prodejních cen',
    system_price_coeficient: 'Koeficient výpočtu ceny',
    system_price_coeficient_enable: 'Přepočet přes koeficient',
    system_price_coeficient_note: 'Prodejní cena se vypočítá (nákupní cena * koeficient)',
    system_price_coeficient_category_note: 'Výpočet prodejní ceny přes koeficient pro celou kategorii',

    modules_settings: 'Nastavení jednotlivých modulů',
    modules_numbering: 'Číslování záznamů',
    modules_numbering_default: 'Přednastavené číslování',
    modules_sms_pattern: 'SMS nákupy kreditu',
    modules_product_pattern: 'Číslování produktů',
    modules_product_auto: 'Automatické číslování produktů',

    // **********************************************************************    
    // USER / ORGANIZATION / COMPANY
    // **********************************************************************

    user: 'Uživatel',
    users: 'Uživatelé',
    user_enabled: 'Aktívny',
    users_list: 'Seznam uživatelů',
    users_groups: 'Skupiny',
    users_group: 'Skupina',
    users_group_new: 'Nová skupina',
    users_groups_: 'Skupiny uživatelů',
    users_roles: 'Pozice',
    users_roles_: 'Uživateľské pozice',
    users_roles_text: 'Na této stránce můžete definovat jako sdílet údaje mezi jednotlivými uživateli v rámci jedné organizace.',
    users_role: 'Pozice',
    user_new: 'Nový uživatel',
    user_info: 'Informace o uživateli',
    user_register: 'Registrace nového uživatele',
    user_groups: 'Uživatelské skupiny',
    user_groups_text: 'Skupiny dovolují radení jednotlivých uživatelů do skupin.',
    user_group_enabled: 'Aktivní skupina',
    user_group_label: 'Název skupiny',
    user_group_info: 'Informace',
    user_group_edit: 'Úprava skupiny',
    users_group_list: 'Seznam uživatelů ve skupině',
    users_groups_all: 'Všechny skupiny',
    super_admin: 'Super admin',
    user_invite: 'Odeslat pozvánku',
    users_add: 'Přidat uživatele',
    user_select: 'Výběr uživatele',
    company_name: 'Název firmy',

    basic_info: 'Základní informace',
    contact_info: 'Kontaktní údaje',
    jméno: 'Jméno',
    surname: 'Příjmení',
    name_surname: 'Jméno a příjmení',
    alias: 'Alias',
    address: 'Adresa',
    street: 'Ulice',
    psc: 'PSČ',
    town: 'Město',
    state: 'Stát',
    birtdate: 'Dátum narodenia',
    ico: 'IČ',
    dic: 'DIČ',
    ic_dph: 'IČ DPH',
    bank: 'Název banky',
    iban: 'IBAN',
    swift: 'SWIFT',
    currency: 'Platební měna',
    phone: 'Telefon',
    mobil: 'Mobil',
    mobil_international: 'Číslo musí být v mezinárodním tvaru (+420...)',
    email: 'Email',
    email_address: 'e-mailová adresa',
    url: 'Webová adresa',
    note: 'Poznámka',
    firm_data: 'Firemní údaje',
    contact_data: 'Kontaktní údaje',
    dph_pay: 'Platca dph',

    user_profile: 'Můj profil',
    user_profile_info: 'Základní informace',
    user_profile_login: 'Přihlašování do aplikace',
    user_profile_2fa: 'Dvoufaktorové ověření',
    user_profile_2fa_note: 'Dvoufaktorové ověření při přihlašování přes SMS zprávu',
    user_profile_mobil_verify: 'Ověření mobilního čísla',
    user_profile_mobil_text: 'Pro spuštění dvoufaktorového ověřování přes SMS je třeba ověření mobilního čísla.',
    user_profile_mobil_number: 'Na vaše mobilní číslo @mobil bude odeslán verifikační kód',
    user_profile_mobil_number_send: 'Odeslat kód',
    user_profile_mobil_number_cancel: 'Vypnout ověřování',
    user_profile_mobil_verified: 'Ověřené číslo',

    // **********************************************************************    
    // FOTOGRAFIE
    // **********************************************************************

    photos: 'Fotografie',
    photo: 'Fotografie',
    photo_select_title: 'Výběr a úprava fotografie',
    photo_select: 'Vybrat fotografii',
    photo_change: 'Změnit fotografii',
    photo_delete: 'Vymazat',
    photo_delete_text: 'Chcete vymazat fotografii?',
    photo_save: 'Vložit',
    photo_min: 'Minimální velikost',

    // **********************************************************************    
    // PERMISSIONS - povolenia
    // **********************************************************************

    permission: 'Povolení',
    permissions: 'Povolení',
    profil: 'Uživatelsky Profil',
    profiles: 'Uživatelské Profily',
    profiles_text: 'Profil je soubor povolení pro jednotlivé moduly v aplikaci. Jednotlivým uživatelům můžete přiřadit různé profily.',
    profil_new: 'Nový profil',
    profil_edit: 'Úprava profilu',
    profil_name: 'Název profilu',
    profil_enabled: 'Aktivní profil',

    permission_none: 'Žádné',
    permission_view: 'Prohlížet',
    permission_create: 'Vytvářet',
    permission_edit: 'Upravovat',
    permission_delete: 'Vymazávat',
    permission_export: 'Exportovat',

    permissions_modules: 'Povolení pro jednotlivé moduly',
    permissions_credit_buy: 'Koupě SMS kreditu',

    // **********************************************************************    
    // PRODUKTY
    // **********************************************************************

    products: 'Produkty',
    product: 'Produkt',
    products_categories: 'Produktové kategorie',
    products_groups: 'Produktové skupiny',
    products_manufactures: 'Výrobci produktů',
    products_vendors: 'Dodavatelé produktů',
    products_list: 'Seznam produktů',

    product_info: 'Informace o produktu',
    product_select: 'Výběr produktu',
    product_name: 'Název produktu',
    product_code: 'Kód produktu (SKU)',
    product_code_: 'Kód',
    product_code_external: 'Dodatočný kód',
    product_code_external_: 'Doplňkové kódy produktu',
    product_code_error: 'Produkt s daným kódem se již nachází v databázi',
    product_code_ean: 'Kódy EAN',
    product_code_vendor: 'Kódy dodavatelů',
    product_code_vendor_: 'Kódy dodavatele',
    product_tags: 'Klíčová slova',
    product_new: 'Nový produkt',
    product_enabled: 'Produkt aktivní',
    product_image: 'Obrázek produktu',
    product_typ: 'Typ produktu',
    product_typ_normal: 'Produkt',
    product_typ_variant: 'Variační produkt',
    product_variations: 'Variace produktu',
    product_variations_enabled: 'Produkt obsahuje variace',
    product_variations_text: 'Před vytvořením variací je třeba vytvořit a uložit hlavní produkt',
    product_date_start_: 'Začátek prodeje produktu',
    product_date_start: 'Začátek prodeje',
    product_date_end: 'Konec prodeje',
    product_date_endless: 'Neomezený prodej',
    product_prices: 'Cena produktu',
    product_cost: 'Nákupní cena',
    product_price: 'Prodejní cena',
    product_price_coeficient: 'Prodejní cena bude vypočtena přes koeficient',
    product_price_retail: 'Doporučená cena',
    product_quantity_stock_: 'Skladem',
    product_ranged: 'Časově omezeno',
    product_range_disabled: 'Dočasně vypnuto',
    product_price_ranged: 'Dočasná cena produktu',
    product_price_ranged_: 'Dočasná cena',
    product_price_start: 'Začátek změny ceny',
    product_price_end: 'Konec změny ceny',
    product_dph: 'Dph',
    product_show_dph: 'Zobrazovat DPH',
    product_show_typ: 'Zobrazovat typ',
    product_stock_information: 'Skladové informace',
    product_description_information: 'Popis produktu',
    product_description_short: 'Krátký popis',
    product_quantity_stock: 'Počet kusů skladem',
    product_quantity_package: 'Počet kusů v balení',
    product_unit: 'Jednotka',
    product_settings: 'Nastavení produktu',
    product_guarantee: 'Délka záruky (měsíců)',
    product_date_start_error: 'Datum začátku prodeje musí být nižší než datum konce prodeje!',
    product_price_start_error: 'Datum začátku dočasné ceny musí být nižší než datum konce dočasné ceny!',
    product_variations_name: 'Název variaci',
    product_variation: 'Variace',
    product_variations_: 'Variace',
    product_variation_new: 'Nová variace',

    product_variation_add_product: 'Doplnit produkt',
    product_variation_add_product_label: 'Vložení produktu do variace',
    product_variation_add_product_ask: 'Chcete vložit existující produkt do variačního produktu?',
    product_variation_add_product_note: 'Vybraný produkt se stává součástí variačního produktu.',
    product_variation_eject: 'Osamostatnit produkt',
    product_variation_eject_ask: 'Chcete vytvořit z variace samostatný produkt?',
    product_variation_eject_note: 'Produkt se stává standardním typem, nastavení bude zachováno.',

    product_variation_enabled: 'Variace aktivní',
    product_variation_info: 'Informace o variaci',
    product_variation_code: 'Kód variace',
    product_variation_name: 'Název variace',
    product_variation_price: 'Cena variace',
    product_variation_ranged: 'Dočasná cena variace',
    product_variation_image: 'Obrázek variace',
    product_variation_copy_price: 'Shodná s produktem',
    product_sub_products_add: 'Přidat produkt',
    product_sub_products_label: 'Produkty v balíčku',
    product_delete_from_list: 'Vymazat produkt ze seznamu?',

    product_type_standart: 'Standardní',
    product_type_variant: 'Variační',
    product_type_group: 'Balíček',

    products_filter_enabled: 'Zobrazit pouze aktivní',
    products_filter_unabled: 'Zobrazit neaktivní',
    products_filter_not_started: 'Zobrazit naplánováno',
    products_filter_ended: 'Zobrazit ukončeno',
    products_filter_condition: 'Zobrazit pouze s podmínkou',

    product_group: 'Skupina',
    product_groups: 'Skupiny',
    product_groups_: 'Skupiny produktů',
    product_group_: 'Skupina produktů',
    product_group_new: 'Nová skupina',
    product_group_edit: 'Úprava skupiny',
    product_group_name: 'Název skupiny',
    product_group_enabled: 'Skupina aktivní',
    product_group_number: 'Počet produktů',
    product_group_products_add: 'Přidat produkty',
    product_group_create_error: 'Označte produkty, které chcete přidat do skupiny',

    product_categories: 'Kategorie',
    product_category: 'Kategorie',
    product_subcategories: 'Podkategorie',
    product_category_new: 'Nová kategorie',
    product_category_enabled: 'Kategorie aktivní',
    product_category_name: 'Název kategorie',
    product_category_parent: 'Vlastník kategorie',
    product_category_update: 'Úprava kategorie',
    product_category_main: 'Hlavní kategorie',
    product_subcategory_add: 'Přidat podkategorii',

    vendors: 'Dodavatelé',
    vendor: 'Dodavatel',
    vendor_enabled: 'Dodavatel aktivní',
    vendor_name: 'Jméno dodavatele',
    vendor_new: 'Nový dodavatel',
    vendor_update: 'Úprava dodavatele',

    manufacturers: 'Výrobci',
    manufacturer: 'Výrobce',
    manufacturer_enabled: 'Výrobce aktivní',
    manufacturer_name: 'Jméno výrobce',
    manufacturer_new: 'Nový výrobce',
    manufacturer_update: 'Úprava výrobce',

    price_list: 'Ceník produktů',
    price_list_: 'Ceník',
    price_lists: 'Ceníky produktů',
    price_lists_: 'Ceníky',
    price_list_new: 'Nový ceník',
    price_list_name: 'Název ceníku',
    price_list_enabled: 'Ceník aktivní',
    price_list_ranged: 'Neomezená',
    price_list_date_from: 'Platnost od',
    price_list_date_to: 'Platnost do',
    price_list_life: 'Platnost ceníku',
    price_list_all_products: 'Všechny produkty',
    price_list_all_products_note: 'Zahrnout do ceníku všechny produkty, nebo výběr produktů',
    price_list_products_selected: 'Výběr produktů',
    price_list_source: 'Počítat z ceny',
    price_list_source_note: 'Výsledně ceny budou počítány ...',
    price_list_source_1: 'z nákupní ceny produktů',
    price_list_source_2: 'z prodejní ceny produktů',
    price_list_coeficient: 'Koeficient výpočtu ceny',
    price_list_ranges: 'Množstevní slevy',
    price_list_groups: 'Cenové skupiny',
    price_list_group_new: 'Nová skupina',
    price_list_group: 'Cenová skupina',
    price_list_group_enabled: 'Skupina aktivní',
    price_list_group_name: 'Název skupiny',
    price_list_group_coeficient_note: 'Výpočet ceny pro všechny produkty v dané skupině',
    price_list_product_delete: 'Vymazat ze skupiny',
    price_list_price_default: 'Původní cena',
    price_list_price_product: 'Cena produktu',
    price_list_price_product_change: 'Změna ceny produktu',
    price_list_price_product_changes: 'Změna ceny',
    price_list_price_enter: 'Zadat pevnou cenu',
    price_list_discount: 'Sleva',
    price_list_add: 'Přirážka',
    price_list_discount_add: 'Sleva / Přirážka',
    price_list_products_in_category: 'Produkty v kategorii',
    price_list_groups_from_categories: 'Vytvořit skupiny',
    price_list_groups_from_categories_note: 'Vytvořit samostatné skupiny pro každou podkategorii?',
    cenník_skupiny_podkategórie: 'Zahrnout podkategorie',
    price_list_groups_subcategories_note: 'Do kategorie budou kontrolovány i podkategorie produktů',
    price_list_show_changes: 'Zobrazovat pouze změny',
    price_list_change_price: 'Aplikovat změnu ceny',
    price_list_change_price_note: 'Zadat pevnou cenu, nebo koeficient přepočtu',
    price_list_manual_price: 'Pevná cena',
    price_list_coefficient: 'Koeficient',

    // **********************************************************************    
    // SYSTEM LOGS
    // **********************************************************************

    system_logs: 'Systémové logy',
    logs_logins: 'Přihlašování',
    logs_cost_changed: 'Změna nákupní ceny z @cost_from @cur na @cost_to @cur',
    logs_price_changed: 'Změna prodejní ceny z @cost_from @cur na @cost_to @cur',

    // **********************************************************************    
    // SMS - NOTIFIKACIE
    // **********************************************************************    

    sms_notifies: 'SMS notifikace',
    sms_notify: 'SMS notifikace',
    sms_notify_text: 'SMS notifikace vám umožní posílat informační SMS zprávy vašim zákazníkům a uživatelům CRM',
    sms_notify_enable: 'Povolit sms notifikaci pro CRM',
    sms_notify_settings: 'Nastavení notifikací',
    sms_notify_sender: 'Odesílatel SMS',
    sms_notify_sender_note: 'max.11 znakový text s názvem odesílatele SMS bez diakritiky',
    sms_notify_unicode: 'Používat diakritiku',
    sms_notify_unicode_note: 'Používat diakritiku v textu oznámení',
    sms_notify_label: 'Přehled SMS notifikací',
    sms_notify_message: 'Text zprávy',
    sms_notify_credit_buy: 'Dobit kredit',
    sms_notify_credit_buy_label: 'Dobít SMS kredit',
    sms_notify_credit_status: 'Aktuální stav kreditu',
    sms_notify_credit_stat: 'Přehled využívání kreditu',
    sms_notify_credit_buyed: 'Zakoupený kredit',
    sms_notify_credit_used: 'Použitý kredit',
    sms_notify_credit_receiver: 'Příjemce',
    sms_notify_credit_sender: 'Odesílatel',
    sms_notify_credit_list: 'Seznam SMS zpráv',
    sms_notify_credit_details: 'Informace o odeslané zprávě',
    sms_notify_credit_stat: 'Využití kreditu',
    sms_notify_credit_stat_label: 'Využití kreditu v jednotlivých měsících',
    sms_credit_watchdog: 'Upozornění',
    sms_credit_watchdog_note: 'Upozornit, pokud kredit klesne pod stanovenou výši',
    sms_credit_watchdog_email: 'Upozornění e-mail',
    sms_credit_watchdog_email_note: 'e-mail, kde bude zasláno upozornění',
    sms_credit_buy_note: 'Výše zbývajícího kreditu bude navýšena do 24 hodin. po koupi',
    sms_credit_buy_success_text: 'Kredit byl úspěšně objednán.',
    sms_credit_purchase_list: 'Seznam objednávek SMS kreditů',
    sms_credit_purchase_detail: 'Objednávka SMS kreditu',
    sms_credit_purchase_credit: 'Počet kreditů',
    sms_credit_purchase_price: 'Částka za kredity',
    sms_credit_purchase_state: 'Stav objednávky',
    sms_credit_purchase_cancel: 'Zrušit objednávku',
    sms_credit_purchase_date: 'Datum objednávky',
    sms_credit_purchase_status: ['Objednáno', 'Probíhá zpracování', 'Stornována', 'Dokončena', 'Zamítnuta'],

    // **********************************************************************    
    // TVORBA CISLA DOKUMENTOV
    // **********************************************************************  

    document_number_title: 'Tvorba čísla dokumentů',
    document_number_format: 'Formát čísla',
    document_number_prefix: 'Předpona',
    document_number_char: 'Znak',
    document_number_char_none: 'žádný',
    document_number_value: 'Hodnota',
    document_number_value_none: '---',
    document_number: 'Poradní číslo',
    document_number_decimal: 'Počet číslic v pořadovém čísle',
    document_number_error: 'Chyba - není zvoleno pořadové číslo!',

    // **********************************************************************    
    // NAZVY MODULOV
    // **********************************************************************  
    models_names: [
        'Organizace',
        'Uživatelé',
        'Jazyky',
        'Měna',
        'Nastavení',
        'Podmínky',
        'Povolení',
        'Uživatelské skupiny',
        'Výrobci',
        'Dodavatelé',
        'Exportované údaje',
        'Produktové kategorie',
        'Produkty'
    ],

    // **********************************************************************    
    // SKLADY
    // ********************************************************************** 

    stock: 'Sklad',
    stocks: 'Sklady',
    stocks_use: 'Používání skladů',
    stocks_use_note: 'Vedení skladového stavu produktů ve více skladech',
    stock_one: 'Jeden sklad',
    stock_multi: 'Více skladů',
    stock_name: 'Název',
    stock_code: 'Kód skladu',
    stock_new: 'Nový sklad',
    stock_enabled: 'Sklad aktivní',
    stock_default: 'Hlavní sklad',
    stock_default_note: 'Označit sklad jako hlavní sklad produktů',
    stocks_status: 'Skladové stavy',
    stocks_disabled: 'Používání více skladů je vypnuto!',
    stocks_disabled_note: 'Po změně nastavení již nebude možné používání skladů vypnout.',
    stocks_enabled: 'Začít používat',
    stocks_enabled_ask: 'Chcete začít používat více skladů než jeden?',
    stock_starting_quantity: 'Počáteční stav skladu',

    stock_adjustment_name: 'Název',
    stock_adjustments: 'Úpravy zásob',
    stock_adjustment: 'Úprava zásob',
    stock_adjustment_: 'Úprava skladových zásob',
    stock_adjustment_new: 'Nová úprava',
    stock_adjustment_new_: 'Nová úprava skladových zásob',
    stock_adjustment_enabled: 'Úprava aktivní',
    stock_adjustment_reason: 'Důvod změny',
    stock_adjustment_reason_add: 'Úprava seznamu',
    stock_adjustment_products: 'Seznam produktů',
    stock_adjustment_products_add: 'Přidat produkt',
    stock_original_quantity: 'Původní stav',
    stock_original_quantity_: 'Původní počet kusů',
    stock_adjustment_quantity: 'Upravený stav',
    stock_quantity: 'Počet kusů',
    stock_quantity_adjusted: 'Upravený počet kusů',
    stock_zero_status: 'Zobrazit nulové stavy',
    stock_smaller_status: 'Zobrazit stav menší než',
    stock_smaller_status_label: 'Zobrazit stav skladu menší než',
    stock_filtr: 'Zobrazit',
    stock_filters: 'Stavy skladů',
    stock_product_history: 'Pohyby skladových stavů produktu',
    stock_history_type: ['Počáteční stav skladu', 'Úprava skladových zásob', 'Přesun skladových zásob'],

    stock_transports: 'Přesuny skladových zásob',
    stock_transport: 'Přesun skladových zásob',
    stock_transport_name: 'Název',
    stock_transport_new: 'Nový přesun',
    stock_transport_reason: 'Důvod přesunu',
    stock_transport_products: 'Seznam produktů',
    stock_transport_products_add: 'Přidat produkt',
    stock_transport_quantity: 'Počet kusů',
    stock_transport_quantity_: 'Počet přesouvaných kusů',
    stock_transport_from: 'Ze skladu',
    stock_transport_to: 'Na sklad',
    stock_transport_from_to_error: 'Sklady nesmějí být stejné!',
    stock_transport_from_error: 'Není zvolen sklad, ze kterého se budou produkty přesouvat!',
    stock_transport_to_error: 'Není zvolen sklad, do kterého se budou produkty přesouvat!',
    stock_transport_run: 'Uskutečnit přesun',
    stock_transport_status: ['V přípravě', 'Přesunuto'],
    stock_transport_ask_text: 'Uskutečnit přesun produktů?',
    stock_transport_ask_sub_text: 'Po přesunu již nebude možné měnit stav přesouvaných produktů.',

    warehouse: 'Skladové zásoby',
    warehouse_all: 'Všechny sklady dohromady',
    warehouse_products: 'Sklad produktů',
    warehouse_default: 'Výchozí sklad',

    codebook: 'Číselník',
    codebooks: 'Číselníky',
    codebook_add: 'Nová položka',
    codebook_item_exists: 'Položka se již nachází v seznamu!',

    // **********************************************************************    
    // ZAKAZNICI
    // ********************************************************************** 

}