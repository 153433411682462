/*
*
* =================================================================================================================
* SK - LANGUAGE
* =================================================================================================================
*
*/

export const en = {

    // **********************************************************************    
    // APLIKACIA
    // **********************************************************************

    title: 'Vision CRM',
    title_welcome: 'Welcome to the Vision CRM system',
    region: 'Country',
    language: 'EN',
    state: 'State',

    // **********************************************************************    
    // PRIHLASENIE DO APLIKACIE
    // **********************************************************************

    login: 'Login',
    login_email: 'Email address',
    loginname: 'Login name (email)',
    loginname_error: 'Login name must be an email address',
    login_data: 'Login data',
    email_error: 'Invalid email address format',
    username: 'Login name',
    password: 'Password',
    password_login: 'Login password',
    password_old: 'Original password',
    password_old_err: 'The original password is incorrect',
    password_: 'Password (minimum length 8 characters)',
    password_new: 'New password (minimum 8 characters)',
    password_new_account: 'Create a new password (minimum 8 characters)',
    password_new_again: 'Retry new password',
    passwords_not_equal: 'Entered passwords do not match',
    password_ok: 'Password was successfully changed',
    password_error: 'An error occurred while changing the password. Try the request again.',
    password_error_len: 'The minimum password length must be 8 characters',
    password_change: 'Change password',
    password_change_: 'Change Password',
    password_strong: 'Password strong',
    passwords: ['Insufficient', 'Weak', 'Average', 'Safe'],
    login_button: 'Login',
    lost_password_button: 'Forgot your password?',
    login_error: 'Wrong login details!',
    lost_password: 'Forgot password',
    lost_login_error: 'The entered e-mail address is not in the system',
    login_pin_code_error: 'Wrong verification code',
    login_pin_code: 'Enter code from email',
    login_email_send: 'We have sent a verification code to your email address. Also check your spam folder.',
    password_changed: 'Password changed successfully',
    logout_title: 'Logout',
    logout_ask: 'Do you want to log out of the application?',
    email_exists: 'The entered e-mail address is already registered in CRM. Enter a different address.',
    link_error: 'Error 404 - Invalid link',
    module: 'Module',
    modules: 'Modules',
    system: 'System',
    appearance: 'Appearance',
    preview_mode: 'Preview mode',
    number: 'Number',
    no_name: 'No name',

    register: 'Registration',
    register_: 'Register',
    register_new: 'New registration',

    // **********************************************************************    
    // CALENDAR
    // **********************************************************************

    year: 'Year',
    month: 'Month',
    day: 'Day',

    // **********************************************************************    
    // SYSTEM
    // **********************************************************************

    db_error: 'An error occurred while retrieving information from the DB!',
    db_error_text: 'Try to repeat the request.',
    enabled: 'Enabled',
    enabled: 'En.',
    disabled: 'Disabled',
    close: 'Close',
    ok: 'OK',
    back: 'Back',
    continue: 'Continue',
    choose: 'Choice',
    choose_: 'Choose',
    save: 'Save',
    cancel: 'Cancel',
    required: 'Required',
    yes: 'Yes',
    no: 'No',
    node_root: 'CEO',
    node_parent: 'Owner',
    node_name: 'Role',
    node_name_: 'Role name',
    node_share: 'Share data',
    node_share_: 'Share data with peers',
    node_editing: 'Editing user roles',
    node_edit: 'Edit role',
    calculator: 'Calculator',
    textfield_calc_1: 'This text field also works as a calculator',
    textfield_calc_2: 'e.g. (100 + 50) * 2.1',
    required_red: 'Text fields marked in red are required',
    search: 'Search',
    search_filter: 'Search by',
    empty_list: 'No entries',
    created: 'Created',
    updated: 'Updated',
    edit: 'Edit',
    app_language: 'Application language',
    app_country: 'CRM country',
    app_country_change: 'Allow change country',
    info: 'Informations',
    info_: 'Information',
    delete: 'Delete',
    credit: 'Credit',
    credits: 'Credits',
    credits_: 'Credits',
    credit_price: 'Credit price',
    buy: 'Buy',
    sms_code: 'SMS code',
    sms_code_error: 'Invalid code',
    sms_code_last_try: 'Last try',
    sms_code_sended: 'Enter the verification SMS code from your mobil phone',
    ip_address: 'IP address',
    date: 'Date',
    order: 'Order',
    orders: 'Orders',
    application: 'Application',
    error: 'Error',
    sms_error_text1: 'Failed to send SMS message',
    sms_error_text2: 'Try to repeat the request or consult an administrator.',
    setting: 'Setting',
    settings: 'Settings',
    history: 'History',
    logs_history: 'History of changes made',
    logs_status: [
        'created',
        'modified',
        'deleted',
        'changed to active',
        'changed to inactive',
        'variation created',
        'variation modified',
        'variation status changed to active',
        'variation status changed to inactive',
        'prices change',
        'change in purchase price',
        'change in selling price',
        'variation price change',
        'variation purchase price change',
        'variation selling price change',
    ],
    default_image: 'Profile image',
    app_error: 'An error occurred when entering the application. Check your internet connection and restart the app!',
    internet_error: 'It seems that you are not connected to the Internet.',
    internet_error_text: 'Check your internet connection and restart the app!',
    export: 'Export',
    export_pdf: 'Export to PDF',
    export_xls: 'Export to XLS',
    export_all: 'Export All',
    export_selected: 'Export selected',
    export_note: 'Data export note',
    export_deleting: 'Downloads are valid for 7 days',
    export_files: 'Export files',
    export_columns: 'Select columns to export',
    sorting: 'Sorting',
    ordering: 'Ordering',
    items_count: 'Number of items',
    file: 'File',
    files: 'Files',
    download: 'Download file',
    downloaded: 'Downloaded file',
    close_all: 'Close all',
    open_all: 'Open all',
    open: 'Open',
    hide_decimal: 'Hide decimal places',
    show_decimal: 'Show decimal places',
    hide_manufacture: 'Hide manufacturer',
    show_manufacture: 'Show manufacturer',
    date_include: 'Date including selected day',
    piece: 'pcs',
    tags: 'Keywords',
    tags_text: 'Separate words with a space',
    undefined: 'Undefined',
    batch_update: 'Batch update',
    show_variations: 'Show variations',
    hide_variations: 'Hide variations',
    temporary: 'Temporary',
    temporary_: 'Temporary',
    export_none_error: 'No items are marked',
    export_none_error_: 'Mark the items to be exported',
    change: 'Change',
    changes: 'Changes',
    reset: 'Reset',
    text_percentage: 'Percentages / Coefficient',
    text_percentage_text_1: 'Enter coefficient',
    text_percentage_text_2: 'Coefficient e.g. 1.10 means +10%, 0.95 means -5%',
    text_percentage_text_3: 'Enter percentage - the % character must be used at the end',
    text_percentage_text_4: 'Percentage e.g. +10%, -5%',
    new_record: 'New Record',
    automatic: 'Automatic',
    manual: 'Manual',
    code_name: 'Code name',
    bytes_left: '@byte Bytes left to insert data',
    bytes_left_out: 'Not enough space to insert data',
    insert_press_enter: 'After typing press "Enter"',
    delete_item_ask: 'Delete item?',
    cancel_filters: 'Cancel filters',
    filters: 'Filtering records',
    status: 'Status',
    count: 'Počet',
    count: 'Count',
    label: 'Title',
    system_match: 'According to the system setting',
    priority: 'Order',
    priority_up: 'Move up',
    priority_down: 'Move down',

    // **********************************************************************    
    // CALENDAR
    // **********************************************************************

    year: 'Year',
    month: 'Month',
    day: 'Day',

    // **********************************************************************    
    // CONDITIONS - PODMIENKY
    // **********************************************************************

    cond: 'Condition',
    conds: 'Conditions',
    cond_designer: 'Condition Designer',
    cond_next: 'Next condition',
    cond_exe: 'Trigger condition',
    cond_true: 'Condition fulfilled',
    cond_false: 'Unfulfilled condition',
    cond_yes: 'Yes',
    cond_no: 'No',
    cond_result: 'Result',
    cond_result_condition: 'Condition Result',
    cond_pattern: 'Evaluation Pattern',
    cond_undefined: 'Undefined',
    cond_and: 'And',
    cond_or: 'Or',
    cond_include: 'Contains',
    cond_starts: 'Starts',
    cond_ends: 'Ends',
    cond_name: 'Condition name',

    cond_read: 'When reading data',
    cond_write: 'When writing data',
    cond_choose: 'Choice',
    cond_error: 'Data entered incorrectly',
    cond_result_error: 'The values of the result variable are not defined',
    cond_condition_error: 'Condition must not be empty',

    cond_product_quantity: 'Number of pieces',
    cond_product_label: 'Product Name',
    cond_product_enabled: 'Active product',
    cond_date: 'Date',
    cond_enabled: 'Active',
    cond_price: 'Price',
    cond_time: 'Time',
    cond_email: 'Email',
    cond_stock: 'In stock',

    cond_new: 'New condition',
    cond_update: 'Condition Update',
    cond_note: 'Creating conditional display of product',
    cond_active: 'Condition - active',
    cond_delete_ask: 'Delete condition?',
    cond_copy_value: '"..." original value',
    cond_used: 'Usage',
    cond_used_items: 'Condition Usage',

    // **********************************************************************    
    // NASTAVENIE ORGANIZACIE
    // **********************************************************************

    organization: 'Organization',
    organization_settings: 'Organization settings',
    organization_name: 'Name',
    organization_name_: 'Company name',
    organization_info: 'Organization information',
    app_settings: 'Application settings',
    app_customize: 'Customize',
    application: 'Application',
    application_customize: 'Application customization',

    system_settings: 'CRM System Settings',
    system_precision: 'Number of decimal places',
    system_precision_note: 'Number of decimal places displayed in prices',
    system_precision_1: '2 decimal places',
    system_precision_2: '3 decimal places',
    system_price_creator: 'Creating selling prices',
    system_price_coefficient: 'Price calculation coefficient',
    system_price_coefficient_enable: 'Conversion via coefficient',
    system_price_coefficient_note: 'The selling price is calculated (purchase price * coefficient)',
    system_price_coeficient_category_note: 'Calculation of selling price through the coefficient for the whole category',

    modules_settings: 'Individual module settings',
    modules_numbering: 'Record numbering',
    modules_numbering_default: 'Default numbering',
    modules_sms_pattern: 'SMS credit purchases',
    modules_product_pattern: 'Product numbering',
    modules_product_auto: 'Automatic product numbering',

    // **********************************************************************    
    // USER / ORGANIZATION / COMPANY
    // **********************************************************************

    user: 'User',
    users: 'Users',
    user_enabled: 'Active',
    users_list: 'Users list',
    users_groups: 'Groups',
    users_group: 'Group',
    users_group_new: 'New group',
    users_groups_: 'Users groups',
    users_roles: 'Roles',
    users_roles_: 'Users roles',
    users_roles_text: 'On this page you can define how data will be shared between individual users within one organization.',
    users_role: 'Role',
    user_new: 'New user',
    user_info: 'User informations',
    user_register: 'New user registration',
    user_groups: 'Users groups',
    user_groups_text: 'Groups allow individual users to be assigned to groups.',
    user_group_enabled: 'Active Group',
    user_group_label: 'Group Name',
    user_group_info: 'Informations',
    user_group_edit: 'Group edit',
    users_group_list: 'List of users in the group',
    users_groups_all: 'All groups',
    super_admin: 'Super admin',
    user_invite: 'Send invitation',
    users_add: 'Add users',
    user_select: 'Select user',
    company_name: 'Company name',

    basic_info: 'Basic information',
    contact_info: 'Contact details',
    name: 'Name',
    surname: 'Surname',
    name_surname: 'First and last name',
    alias: 'Alias',
    address: 'Address',
    street: 'Street',
    psc: 'Postcode',
    town: 'City',
    state: 'State',
    birtdate: 'Birth date',
    ico: 'ID',
    dic: 'DIC',
    ic_dph: 'VAT number',
    bank: 'Bank name',
    iban: 'IBAN',
    swift: 'SWIFT',
    currency: 'Currency',
    phone: 'phone',
    mobile: 'mobile',
    mobil_international: 'The number must be in international format (+XXX...)',
    email: 'Email',
    email_address: 'e-mail address',
    url: 'Web address',
    note: 'Note',
    firm_data: 'Company data',
    contact_data: 'Contact data',
    updated_by: 'Updated by',

    user_profile: 'My Profile',
    user_profile_info: 'Basic Information',
    user_profile_login: 'Application Login',
    user_profile_2fa: 'Two-factor authentication',
    user_profile_2fa_note: 'Two-factor authentication when logging in via SMS message',
    user_profile_mobil_verify: 'Mobile number verification',
    user_profile_mobil_text: 'Mobile number verification is required to start two-factor authentication via SMS.',
    user_profile_mobil_number: 'A verification code will be sent to your mobile number @mobil',
    user_profile_mobil_number_send: 'Send code',
    user_profile_mobil_number_cancel: 'Turn off verification',
    user_profile_mobil_verified: 'Verified number',

    // **********************************************************************    
    // FOTOGRAFIE
    // **********************************************************************

    photos: 'Photos',
    photo: 'Photos',
    photo_select_title: 'Photo selection and editing',
    photo_select: 'Select photo',
    photo_change: 'Change photo',
    photo_delete: 'Delete',
    photo_delete_text: 'Do you want to delete the photo?',
    photo_save: 'Paste',
    photo_min: 'Minimum size',

    // **********************************************************************    
    // PERMISSIONS - povolenia
    // **********************************************************************

    permission: 'Permission',
    permissions: 'Permissions',
    profil: 'Profil',
    profiles: 'Profiles',
    profiles_text: 'A profile is a set of permissions for individual modules in the application. You can assign different profiles to individual users.',
    profil_new: 'New profil',
    profil_edit: 'Profil update',
    profil_name: 'Profil name',
    profil_enabled: 'Active',

    permission_none: 'None',
    permission_view: 'View',
    permission_create: 'Create',
    permission_edit: 'Edit',
    permission_delete: 'Delete',
    permission_export: 'Export',

    permissions_modules: 'Permissions for individual modules',
    permissions_credit_buy: 'SMS credit purchase',

    // **********************************************************************    
    // PRODUKTY
    // **********************************************************************

    products: 'Products',
    product: 'Product',
    products_categories: 'Products categories',
    products_groups: 'Products groups',
    products_manufactures: 'Manufacturers',
    products_vendors: 'Suppliers',
    products_list: 'List of products',

    product_info: 'Product information',
    product_select: 'Product selection',
    product_name: 'Product name',
    product_code: 'Product code (SKU)',
    product_code_: 'Code',
    product_code_external: 'Additional code',
    product_code_external_: 'Additional product codes',
    product_code_error: 'The product with the given code is already in the database',
    product_code_ean: 'Code EAN',
    product_code_vendor: 'Suppliers codes',
    product_code_vendor_: 'Supplier codes',
    product_tags: 'Keywords',
    product_new: 'New product',
    product_enabled: 'Product active',
    product_image: 'Product image',
    product_typ: 'Product type',
    product_typ_normal: 'Product',
    product_typ_variant: 'Variant product',
    product_variations: 'Product variations',
    product_variations_enabled: 'Product contains variations',
    product_variations_text: 'Main product must be created and saved before variations can be created',
    product_date_start_: 'Product sales start',
    product_date_start: 'Start of sale',
    product_date_end: 'End of sale',
    product_date_endless: 'Unlimited sales',
    product_prices: 'Product price',
    product_cost: 'Purchase price',
    product_price: 'Sale price',
    product_price_coefficient: 'The selling price will be calculated through the coefficient',
    product_price_retail: 'Recommended price',
    product_quantity_stock_: 'In stock',
    product_ranged: 'Time limited',
    product_range_disabled: 'Temporary disabled',
    product_price_ranged: 'Temporary product Price',
    product_price_ranged_: 'Temporary price',
    product_price_start: 'Start of price change',
    product_price_end: 'End of price change',
    product_dph: 'VAT',
    product_show_dph: 'Show VAT',
    product_show_typ: 'Show type',
    product_stock_information: 'Stock information',
    product_description_information: 'Product description',
    product_description_short: 'Short description',
    product_quantity_stock: 'Number of pieces in stock',
    product_quantity_package: 'Number of pieces in package',
    product_unit: 'Unit',
    product_settings: 'Product settings',
    product_guarantee: 'Warranty length (months)',
    product_date_start_error: 'The start date of the sale must be lower than the end date of the sale!',
    product_price_start_error: 'The start date of the temporary price must be lower than the end date of the temporary price!',
    product_variations_name: 'Variations name',
    product_variation: 'Variation',
    product_variations_: 'Variations',
    product_variation_new: 'New variation',

    product_variation_add_product: 'Add Product',
    product_variation_add_product_label: 'Insert product into variation',
    product_varation_add_product_ask: 'Do you want to insert an existing product into a variation product?',
    product_variation_add_product_note: 'The selected product becomes part of the variation product.',
    product_variation_eject: 'Eject Product',
    product_variation_eject_ask: 'Do you want to create a separate product from the variation?',
    product_variation_eject_note: 'Product will become standard type, settings will be preserved.',

    product_variation_enabled: 'Variation enabled',
    product_variation_info: 'Variation info',
    product_variation_code: 'Variation code',
    product_variation_name: 'Variation name',
    product_variation_price: 'Price of variation',
    product_variation_ranged: 'Temporary variation price',
    product_variation_image: 'Image of variation',
    product_variation_copy_price: 'Same as product',
    product_group_create_error: 'Mark the products you want to add to the group',
    product_sub_products_add: 'Add product',
    product_sub_products_label: 'Products in package',
    product_delete_from_list: 'Delete product from list?',

    product_type_standart: 'Standard',
    product_type_variant: 'Variant',
    product_type_group: 'Package',

    products_filter_enabled: 'Show only active',
    products_filter_unabled: 'Show disabled',
    products_filter_not_started: 'Show scheduled',
    products_filter_ended: 'Show ended',
    products_filter_condition: 'Show only with condition',

    product_group: 'Group',
    product_groups: 'Groups',
    product_groups_: 'Product Groups',
    product_group_: 'Product Group',
    product_group_new: 'New Group',
    product_group_edit: 'Edit Group',
    product_group_name: 'Group Name',
    product_group_enabled: 'Group enabled',
    product_group_number: 'Number of products',
    product_group_products_add: 'Add Products',

    product_categories: 'Categories',
    product_category: 'Category',
    product_subcategories: 'Subcategories',
    product_category_new: 'New Category',
    product_category_enabled: 'Category enabled',
    product_category_name: 'Category Name',
    product_category_parent: 'Category Owner',
    product_category_update: 'Category Update',
    product_category_main: 'Main Category',
    product_subcategory_add: 'Add subcategory',

    vendors: 'Suppliers',
    vendor: 'Supplier',
    vendor_enabled: 'Supplier enabled',
    vendor_name: 'Supplier Name',
    vendor_new: 'New Supplier',
    vendor_update: 'Supplier Update',

    manufacturers: 'Manufacturers',
    manufacturer: 'Manufacturer',
    manufacturer_enabled: 'Manufacturer enabled',
    manufacturer_name: 'Manufacturer Name',
    manufacturer_new: 'New manufacturer',
    manufacturer_update: 'Manufacturer Update',

    price_list: 'Product price list',
    price_list_: 'Price list',
    price_lists: 'Product price lists',
    price_lists_: 'Price lists',
    price_list_new: 'New price list',
    price_list_name: 'Price list name',
    price_list_enabled: 'Price list active',
    price_list_ranged: 'Unlimited',
    price_list_date_from: 'Valid from',
    price_list_date_to: 'Valid to',
    price_list_life: 'Validity of price list',
    price_list_all_products: 'All products',
    price_list_all_products_note: 'Include all products or a selection of products in the price list',
    price_list_products_selected: 'Product selection',
    price_list_source: 'Calculate from price',
    price_list_source_note: 'The resulting prices will be calculated ...',
    price_list_source_1: 'from purchase price of products',
    price_list_source_2: 'from the selling price of the products',
    price_list_coefficient: 'Price calculation coefficient',
    price_list_ranges: 'Volume discounts',
    price_list_groups: 'Price groups',
    price_list_group_new: 'New Group',
    price_list_group: 'Price Group',
    price_list_group_enabled: 'Group enabled',
    price_list_group_name: 'Group name',
    price_list_group_coefficient_note: 'Price calculation for all products in a given group',
    price_list_product_delete: 'Delete from group',
    price_list_price_default: 'Original price',
    price_list_price_product: 'Product price',
    price_list_price_product_change: 'Product price change',
    price_list_price_product_changes: 'Price change',
    price_list_price_enter: 'Enter fixed price',
    price_list_discount: 'Discount',
    price_list_add: 'Surcharge',
    price_list_discount_add: 'Discount / Surcharge',
    price_list_products_in_category: 'Products in the category',
    price_list_groups_from_categories: 'Create groups',
    price_list_groups_from_categories_note: 'Create separate groups for each subcategory?',
    price_list_groups_subcategories: 'Include subcategories',
    price_list_groups_subcategories_note: 'Products of subcategories will also be included in the category',
    price_list_show_changes: 'Show only changes',
    price_list_change_price: 'Apply price change',
    price_list_change_price_note: 'Enter a fixed price or a conversion coefficient',
    price_list_manual_price: 'Fixed price',
    price_list_coefficient: 'Coefficient',

    // **********************************************************************    
    // SYSTEM LOGS
    // **********************************************************************

    system_logs: 'System Logs',
    logs_logins: 'Logins',
    logs_cost_changed: 'Change of purchase price from @cost_from @cur to @cost_to @cur',
    logs_price_changed: 'Changing selling price from @cost_from @cur to @cost_to @cur',

    // **********************************************************************    
    // SMS - NOTIFIKACIE
    // **********************************************************************  

    sms_notifies: 'SMS notifications',
    sms_notify: 'SMS notification',
    sms_notify_text: 'SMS notification allows you to send informative SMS messages to your customers and CRM users',
    sms_notify_enable: 'Enable sms notification for CRM',
    sms_notify_settings: 'Notification settings',
    sms_notify_sender: 'Sender SMS',
    sms_notify_sender_note: 'max. 11 character text with the name of the SMS sender without diacritics',
    sms_notify_unicode: 'Use Diacritics',
    sms_notify_unicode_note: 'Use diacritics in notification text',
    sms_notify_label: 'SMS notification overview',
    sms_notify_message: 'Message text',
    sms_notify_credit_buy: 'Buy credit',
    sms_notify_credit_buy_label: 'Buy SMS credit',
    sms_notify_credit_status: 'Actual credit status',
    sms_notify_credit_stat: 'Overview of credit usage',
    sms_notify_credit_buyed: 'Credit bought',
    sms_notify_credit_used: 'Credit used',
    sms_notify_credit_receiver: 'Recipient',
    sms_notify_credit_sender: 'Sender',
    sms_notify_credit_list: 'List of SMS messages',
    sms_notify_credit_details: 'Sent message information',
    sms_notify_credit_stat: 'Use of credit',
    sms_notify_credit_stat_label: 'Using credits in individual months',
    sms_credit_watchdog: 'Warning',
    sms_credit_watchdog_note: 'Notify if the credit falls below the specified amount',
    sms_credit_watchdog_email: 'Notification email',
    sms_credit_watchdog_email_note: 'email, where the notification will be sent',
    sms_credit_buy_note: 'The amount of the building credit will be increased within 24 hours. after purchase',
    sms_credit_buy_success_text: 'Credit was successfully ordered.',
    sms_credit_purchase_list: 'List of SMS credit orders',
    sms_credit_purchase_detail: 'Purchase details',
    sms_credit_purchase_credit: 'Number of credits',
    sms_credit_purchase_price: 'Amount for credits',
    sms_credit_purchase_state: 'Order status',
    sms_credit_purchase_cancel: 'Cancel order',
    sms_credit_purchase_date: 'Order date',
    sms_credit_purchase_status: ['Ordered', 'Probíhá zpracování', 'Cancelled', 'Completed', 'Rejected'],

    // **********************************************************************    
    // TVORBA CISLA DOKUMENTOV
    // **********************************************************************  

    document_number_title: 'Document number generation',
    document_number_format: 'Number format',
    document_number_prefix: 'Prefix',
    document_number_char: 'Character',
    document_number_char_none: 'none',
    document_number_value: 'Value',
    document_number_value_none: '---',
    document_number: 'Document number',
    document_number_decimal: 'Number of digits in the document number',
    document_number_error: 'Error - document number is not selected!',

    // **********************************************************************    
    // NAZVY MODULOV
    // **********************************************************************  
    models_names: [
        'Organization',
        'Users',
        'Languages',
        'Names',
        'Settings',
        'Conditions',
        'Permissions',
        'User Groups',
        'Producers',
        'Suppliers',
        'Exported data',
        'Product Categories',
        'Products',
    ],

    // **********************************************************************    
    // SKLADY
    // ********************************************************************** 

    stock: 'Stock',
    stocks: 'Stocks',
    stocks_use: 'Use of stocks',
    stocks_use_note: 'Maintaining stock status of products in multiple warehouses',
    stock_one: 'One stock',
    stock_multi: 'Multiple stocks',
    stock_name: 'Name',
    stock_code: 'Stock code',
    stock_new: 'New stock',
    stock_enabled: 'Stock enabled',
    stock_default: 'Main warehouse',
    stock_default_note: 'Mark warehouse as main product warehouse',
    stocks_status: 'Stock statuses',
    stocks_disabled: 'The use of multiple warehouses is disabled!',
    stocks_disabled_note: 'After changing the settings, it will no longer be possible to disable the use of stocks.',
    stocks_enabled: 'Start using',
    stocks_enabled_ask: 'Do you want to start using more than one stock?',
    stock_starting_quantity: 'Starting stock quantity',

    stock_adjustment_name: 'Name',
    stock_adjustments: 'Stock adjustments',
    stock_adjustment: 'Stock adjustment',
    stock_adjustment_: 'Stock adjustment',
    stock_adjustment_new: 'New adjustment',
    stock_adjustment_new_: 'New stock adjustment',
    stock_adjustment_enabled: 'Adjustment enabled',
    stock_adjustment_reason: 'Reason for change',
    stock_adjustment_reason_add: 'List adjustment',
    stock_adjustment_products: 'List of products',
    stock_adjustment_products_add: 'Add Product',
    stock_original_quantity: 'Original quantity',
    stock_original_quantity_: 'Original number of pieces',
    stock_adjustment_quantity: 'Adjusted stock',
    stock_quantity: 'Number of pieces',
    stock_quantity_adjusted: 'Adjusted number of pieces',
    stock_zero_status: 'Show zero statuses',
    stock_smaller_status: 'Show status smaller than',
    stock_smaller_status_label: 'Show stock status smaller than',
    stock_filter: 'Show',
    stock_filters: 'Stock statuses',
    stock_product_history: 'Product stock movements',
    stock_history_type: ['Initial stock status', 'Stock adjustment', 'Stock transfer'],

    stock_transports: 'Stock transfers',
    stock_transport: 'Transfer of stock',
    stock_transport_name: 'Name',
    stock_transport_new: 'New transport',
    stock_transport_reason: 'Reason for transport',
    stock_transport_products: 'List of products',
    stock_transport_products_add: 'Add Product',
    stock_transport_quantity: 'Number of pieces',
    stock_transport_quantity_: 'Number of transported pieces',
    stock_transport_from: 'From stock',
    stock_transport_to: 'To stock',
    stock_transport_from_to_error: 'Stocks must not be the same!',
    stock_transport_from_error: 'The warehouse from which the products will be moved is not selected!',
    stock_transport_to_error: 'The warehouse to which the products will be moved is not selected!',
    stock_transport_run: 'Perform transport',
    stock_transport_status: ['In preparation', 'Moved'],
    stock_transport_ask_text: 'Transfer products?',
    stock_transport_ask_sub_text: 'After the transfer, it will no longer be possible to change the status of the moved products.',

    warehouse: 'Warehouse stocks',
    warehouse_all: 'All warehouses together',
    warehouse_products: 'Products Warehouse',
    warehouse_default: 'Default warehouse',

    codebook: 'Number Book',
    codebooks: 'Numbers',
    codebook_add: 'New Item',
    codebook_item_exists: 'The item already exists in the list!',

    // **********************************************************************    
    // ZAKAZNICI
    // ********************************************************************** 

}