/*
*
* =================================================================================================================
* 
    SYSTEMOVE LOGY - PRIHLASENIE DO SYSTEMU

    USAGE:
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    <LogsSystem organization={organization} user={user} lang={lang} language={language} locale={locale} theme={theme} offset={offset} radius={radius} isSmall={isSmall} title={lang.cond_designer} token={token} func={() => closeWindow()} />
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    

* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';

import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language_sk.js';
import { cz } from './language_cz.js';
import { en } from './language_en.js';
import { Colors, DialogImagePreview, DialogInfo, DialogLogin, DialogTabs, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, Icon, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Button, Chip, IconButton, Pagination, Paper, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLayerGroup, faList, faPlusCircle, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { GetDatum2, GetDatumTime2, GetTimeHM, GetTodayYear } from './functions_date';
import { CheckPermission, Debug, GetPages } from './functions';

export const LogsSystem = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;


    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');
    const [index, setIndex] = useState(0);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // TABS
    const [tabIndex, setTabIndex] = useState(0);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // VALUES
    const [year, setYear] = useState(GetTodayYear());

    // DIALOGS
    const [showDialog, setShowDialog] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // CONSTANTS
    const tabHeight = 40;
    const menuHeight = 50;
    const rowHeight = 70;
    const menuWidth = global.sub_menu_width;

    const tabs = [
        { id: 0, enabled: true, label: lang.logs_logins },
        { id: 1, enabled: true, label: lang.export_files },
    ];

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, { year: year });

            running = true;
        }

    }, []);

    useEffect(() => {
    }, []);

    const db_get = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'system_logs', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                    start: page * global.items_max,
                    length: global.items_max,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, global.items_max));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectYearResult = (year_, month) => {
        setYear(year_);
        db_get(0, { year: year_ });
    }

    const SelectItem = (item) => {

    }


    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.widow_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <Icon theme={props.theme} icon={faLayerGroup} />
                </div>
                <div style={{ ...styles.Block, width: props.width - 120, height: global.widow_bar_height, alignItems: 'flex-start' }}>
                    <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: 60, height: global.widow_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height, backgroundColor: color.back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height }}>
                        <div style={{ ...styles.BlockCenter, height: props.height - global.widow_bar_height - tabHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            <div style={{ ...styles.Block, maxWidth: global.max_width, width: '100%' }}>
                                <div id={'id_start'} style={{ ...styles.Block, width: '96%' }}>
                                    <div style={{ ...styles.BlockRow, borderBottom: '1px solid ' + color.light_gray }}>
                                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                                            <SelectYear min={2020} value={year} theme={props.theme} func={SelectYearResult.bind(this)} />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <Paper
                                                key={item.id}
                                                elevation={item.id == overID ? 4 : 1}
                                                onClick={() => SelectItem(item)}
                                                style={{
                                                    ...styles.Block,
                                                    backgroundColor: item.enabled == false ? color.disabled : color.white,
                                                    borderRadius: global.items_radius,
                                                    minHeight: rowHeight,
                                                    cursor: 'pointer',
                                                    marginTop: 5, marginBottom: 5,
                                                    paddingTop: 2, paddingBottom: 2,
                                                    borderLeft: item.enabled == false ? color.disabled_border : '',
                                                }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, width: '96%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '60%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.name}</p>
                                                        <div style={{ ...styles.BlockRowRaw, marginTop: 5 }}>
                                                            <Chip size={'small'} variant='outlined' style={{ fontSize: global.font_xtiny, color: color.dark_gray, marginLeft: 10 }} label={item.os} />
                                                            <Chip size={'small'} variant='outlined' style={{ fontSize: global.font_xtiny, color: color.dark_gray, marginLeft: 5 }} label={item.browser_name} />
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '20%', minHeight: rowHeight, justifyContent: 'center', borderRight: '1px solid ' + color.light_gray }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.ip_address}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 3 }}>{item.ip_address}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '20%', minHeight: rowHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny }}>{GetDatumTime2(item.created)}</p>
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                            <EmptyList row small lang={lang} />
                                        }

                                        {/* PAGINATION */}
                                        {pages > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                                <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                            </div>
                                            : null}

                                    </div>

                                </div>
                            </div>
                        </div>

                </div>
            </div>

            {showOK == true ?
                <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset}></Loading>
                : null}

        </Paper >
    );
}


